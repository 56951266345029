<template>
  <div>
    <v-row class="d-flex justify-center mt-12 mb-4">
      <h2>{{ langkeyword("MYORDERS") }}</h2>
    </v-row>

    <v-row class="alert mt-4">
      <v-col
        class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
        v-if="error_msg != null"
      >
        <v-alert dense type="warning">{{ error_msg }}</v-alert>
      </v-col>
      <v-col
        class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
        v-if="success_msg != null"
      >
        <v-alert dense type="success">{{ success_msg }}</v-alert>
      </v-col>
    </v-row>

    <v-container class="mb-10">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="9" lg="9">
          <v-simple-table class="bg_mode rounded-lg">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="">{{ langkeyword("CODE") }}</th>
                  <th class="">{{ langkeyword("DATE") }}</th>
                  <th class="">{{ langkeyword("AMOUNT") }}</th>
                  <th class="">{{ langkeyword("STATUS") }}</th>
                  <th class="">{{ langkeyword("ACTIONS") }}</th>
                  <th class=""></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in orders" :key="item.id">
                  <td>
                    <span>#{{ item.ordercode }}</span>
                  </td>
                  <td>
                    <span>{{ item.date }}</span>
                  </td>
                  <td>${{ item.amount }}</td>
                  <td  v-if="item.status==='ordered'">
                    <span class="rounded px-2 purple m-1 white--text">{{ langkeyword('ORDER_WATING') }}</span></td>
                  <td  v-if="item.status==='checking'">
                    <span class="rounded px-2 red m-1 white--text">{{ langkeyword('ORDER_CHECKING') }}</span></td>
                  <td  v-if="item.status==='delivering'">
                    <span class="rounded px-2 warning m-1 white--text">{{ langkeyword('ORDER_DELIVERING') }}</span></td>
                  <td  v-if="item.status==='delivered'">
                    <span class="rounded px-2 success m-1 white--text">{{ langkeyword('ORDER_DELIVERED') }}</span></td>
                  <td>
                    <v-icon
                      small
                      class="mr-2 blue m-1 white--text px-2 py-2 rounded"
                      @click="$router.push('/orders/' + item.id)"
                    >
                      mdi-eye
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>

    <appFooter />
  </div>
</template>

<script>
import appFooter from "../../layouts/client/footer.vue";
import axios from "axios";
export default {
  components: { appFooter },

  data() {
    return {
      auth_id: this.$store.getters.getclientauth.id,
      orders: [],
      error_msg: null,
      success_msg: null,
    };
  },

  computed: {},
  mounted() {
    axios
      .post("/app/client/orders/getall", {
        getAllOrders: true,
        account_id: this.auth_id,
      })
      .then((res) => {
        if (res.data.status === false) {
          this.success_msg = null;
          this.error_msg = this.langkeyword(res.data.msg);
        } else {
          this.error_msg = null;
          this.orders = res.data.data;
        }
        this.cleanMessages();
      })
      .catch((err) => {
        console.log("response error " + err);
      });
  },
  methods: {
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
  },
};
</script>

<style scoped>
   .mobile {
      color: #333;
    }

    @media screen and (max-width: 768px) {
      .mobile table.v-table tr {
        max-width: 100%;
        position: relative;
        display: block;
      }

      .mobile table.v-table tr:nth-child(odd) {
        border-left: 6px solid deeppink;
      }

      .mobile table.v-table tr:nth-child(even) {
        border-left: 6px solid cyan;
      }

      .mobile table.v-table tr td {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #f5f5f5;
        height: auto;
        padding: 10px;
      }

      .mobile table.v-table tr td ul li:before {
        content: attr(data-label);
        padding-right: .5em;
        text-align: left;
        display: block;
        color: #999;

      }
      .v-datatable__actions__select
      {
        width: 50%;
        margin: 0px;
        justify-content: flex-start;
      }
      .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
        background: transparent;
      }

    }
    .flex-content {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .flex-item {
      padding: 5px;
      width: 50%;
      height: 40px;
      font-weight: bold;
    }
</style>