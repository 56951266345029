<template>
  <div>
    <v-footer class="bg_mode mt-15 client-footer">
      <v-container>
        <div class="d-flex justify-space-between flex-wrap mt-10">
          <v-col cols="12" md="4" lg="2">
            <v-img
              max-width="340"
              src="../../assets/img/footer-logo.png"
            ></v-img>
          </v-col>

          <v-col cols="12" md="4" lg="4" class="">
            <h3 class="indesktop-center">{{ langkeyword("ABOUT_US") }}</h3>
            <p class="text-opacity mt-5 indesktop-center">
              <span v-if="lang() === 'ku'">{{
                this.$store.state.setting.ku_about.substring(0,100)+' ...'
              }}</span>
              <span v-if="lang() === 'ar'">{{
                this.$store.state.setting.ar_about.substring(0,100)+' ...'
              }}</span>
              <span v-if="lang() === 'en'">{{
                this.$store.state.setting.en_about.substring(0,100)+' ...'
              }}</span>
              <v-btn link to="/about" small outlined class="ma-2" color="primary">
                {{langkeyword('SHOW_MORE')}}
              </v-btn>
            </p>
          </v-col>

          <v-col cols="12" md="4" lg="3">
            <h3 class="indesktop-center mb-4">
              {{ langkeyword("CONTACT_US") }}
            </h3>

            <v-row align="center" class="d-flex">
              <v-btn
                target="_blank"
                @click="openLink('tel:'+$store.state.setting.phone)"
                color="green"
                class="mt-4 mx-2 white--text"
                dir="ltr"
              >
                <v-icon>mdi-phone</v-icon>
                {{ this.$store.state.setting.phone }}
              </v-btn>
            </v-row>


            <v-row align="center" class="d-flex">
              <v-btn
                target="_blank"
                @click="openLink('mailto:'+$store.state.setting.email)"
                color="green"
                class="mt-4 mx-2 white--text"
                dir="ltr"
              >
                <v-icon>mdi-email</v-icon>
                {{ this.$store.state.setting.email }}
              </v-btn>
            </v-row>


            <v-row align="center" class="d-flex">
              <v-btn
                target="_blank"
                @click="openLink('https://www.google.com/maps/place/Smart+lifts+Company/@36.1968676,44.0637353,21z/data=!4m17!1m10!4m9!1m4!2m2!1d45.43195!2d35.5638555!4e1!1m3!2m2!1d44.0638123!2d36.1970482!3m5!1s0x400723c1441496f3:0x148c84a924ca335d!8m2!3d36.1969074!4d44.0638543!16s%2Fg%2F11nyz85zl_?entry=ttu')"
                color="green"
                class="mt-4 mx-2 white--text"
                dir="ltr"
              >
                <v-icon>mdi-map-marker</v-icon>
                Erbil - Iraq
              </v-btn>
            </v-row>


            <v-row class="d-flex mt-4">
              <a
                v-for="(item, index) in socials"
                :key="index"
                :href="item.link"
                target="_blank"
                style="text-decoration: none"
              >
                <v-icon class="mx-1">{{ item.icon }}</v-icon></a
              >
            </v-row>
          </v-col>
        </div>
      </v-container>

      <v-col class="text-center" cols="12">
        <h4>
          <A
            class="text-decoration-none color-main"
            href="https://www.facebook.com/fergacenter"
            target="_blank"
          >
            {{ new Date().getFullYear() }} —
            <strong>{{ langkeyword("APP_DEVELOPER") }}</strong></A
          >
        </h4>
      </v-col>
    </v-footer>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
data: () => ({
icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
}),
computed: {
socials() {
return this.$store.state.socials;
},
},
methods:{
openLink(link){
window.open(link,'_blank')
}
},
};
</script>
