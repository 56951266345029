

export default {

    async imageALT() {
        document.querySelectorAll('img').forEach(item => {
            item.setAttribute('alt', 'image')
        })
        document.querySelectorAll('v-img').forEach(item => {
            item.setAttribute('alt', 'image')
        })
    },





}
