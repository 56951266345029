<template>
  <div>
    <v-container>
        <v-row
        class="d-flex justify-center mt-16 bg_mode pa-3"
        style="border-left: 2px solid #01994c; border-right: 2px solid #01994c"
      >
        <h1 class="color-main">{{ langkeyword("ABOUT_US") }}</h1>
      </v-row>
      </v-container>

    <v-row class="p-2  text-center d-flex justify-center mt-16">
      <p class="text-center col-11">
        <span v-if="lang() === 'ku'">{{
          this.$store.state.setting.ku_about
        }}</span>
        <span v-if="lang() === 'ar'">{{
          this.$store.state.setting.ar_about
        }}</span>
        <span v-if="lang() === 'en'">{{
          this.$store.state.setting.en_about
        }}</span>
      </p>
    </v-row>

    <appFooter />
  </div>
</template>

<script>
import appFooter from "../../layouts/client/footer.vue";
export default {
  components: { appFooter },
};
</script>

<style lang="scss" scoped>
</style>