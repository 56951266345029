<template>
    <div>


    <v-carousel
        
        height="560"
        hide-delimiter-background
        class="slider"
      > 
    
      <v-carousel-item v-for="(item, index) in this.$store.state.sliders" :key="index"
          :src="uploadPath('/sliders/'+item.avatar)"
          reverse-transition="fade-transition"
          transition="fade-transition"
          cycle="cycle"
          >
      </v-carousel-item>
    
    
      </v-carousel>



    </div>
</template>

<script>
    export default {
        data() {
            return {
               
            }
        },
    } 
</script>

<style lang="scss" scoped>

</style>