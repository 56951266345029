<template>
  <div>
    <v-container class="show_in_desktop">
      <v-row
        class="d-flex mt-16 bg_mode pa-3"
        style="border-left: 2px solid #01994c; border-right: 2px solid #01994c"
      >
        <h2>
          <span v-if="lang() === 'ku'">{{ info.ku_name }}</span>
          <span v-if="lang() === 'ar'">{{ info.ar_name }}</span>
          <span v-if="lang() === 'en'">{{ info.en_name }}</span>
        </h2>
      </v-row>
    </v-container>

    <v-container class="show_in_desktop">
      <v-row class="mt-2 px-2">
        <p class="color-main">
          {{ langkeyword("PRODUCTCATEGORY") }}:

          <span v-if="lang() === 'ku'">{{
            info.productcategories_ku_name
          }}</span>
          <span v-if="lang() === 'ar'">{{
            info.productcategories_ar_name
          }}</span>
          <span v-if="lang() === 'en'">{{
            info.productcategories_en_name
          }}</span>
        </p>
      </v-row>
    </v-container>

    <v-container class="show_in_mobile">
      <v-row class="d-flex justify-center mt-16 pa-1">
        <h2>
          <span v-if="lang() === 'ku'">{{ info.ku_name }}</span>
          <span v-if="lang() === 'ar'">{{ info.ar_name }}</span>
          <span v-if="lang() === 'en'">{{ info.en_name }}</span>
        </h2>
      </v-row>
    </v-container>

    <v-container class="show_in_mobile">
      <v-row class="mt-2 px-2">
        <p class="color-main">
          {{ langkeyword("PRODUCTCATEGORY") }}:

          <span v-if="lang() === 'ku'">{{
            info.productcategories_ku_name
          }}</span>
          <span v-if="lang() === 'ar'">{{
            info.productcategories_ar_name
          }}</span>
          <span v-if="lang() === 'en'">{{
            info.productcategories_en_name
          }}</span>
        </p>
      </v-row>
    </v-container>

    <v-container class="mt-8">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="5" lg="5">
          <agile
            class="main_slider"
            ref="main"
            :options="options1"
            :as-nav-for="asNavFor1"
          >
            <div class="slide">
              <img class="img"
                :src="uploadPath('/products/' + info.avatar)"
              />
            </div>
          </agile>
          <agile
            class="thumbnails"
            ref="thumbnails"
            :options="options2"
            :as-nav-for="asNavFor2"
          >
            <template slot="prevButton"
              ><i class="fas fa-chevron-left"></i></template
            ><template slot="nextButton"
              ><i class="fas fa-chevron-right"></i
            ></template>
          </agile>
        </v-col>

        <v-col cols="12" md="7" lg="7">
          <h2>{{ langkeyword("DESC") }}</h2>

          <p class="text-justify mt-4">
            <span v-if="lang() === 'ku'">{{ info.ku_detail }}</span>
            <span v-if="lang() === 'ar'">{{ info.ar_detail }}</span>
            <span v-if="lang() === 'en'">{{ info.en_detail }}</span>
          </p>
        </v-col>
      </v-row>
 
      <v-row v-if="productgalaries != ''">
        <v-col cols="12" lg="6" md="6" class="mx-auto">
          <v-carousel hide-delimiters>
            <v-carousel-item
              v-for="(item, index) in productgalaries"
              :key="index"
              :src="uploadPath('/productgalaries/' + item.galary)"
            ></v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-6 mb-10">
      <v-row
        class="d-flex justify-space-between pa-4"
        align="center"
        style="align-items: center !important"
      >
        <span class="mx-2">{{ langkeyword("RELATED_PRODUCTS") }}</span>
        <v-btn outlined class="rounded-xl text-capitalize">{{
          langkeyword("SHOW_MORE")
        }}</v-btn>
      </v-row>

      <v-row class="d-flex justify-center mt-10">
        <v-col
          v-for="(item, index) in products"
          :class="
            item.productcategory_id === info.productcategory_id &&
            item.id != info.id
              ? ''
              : 'd-none'
          "
          :key="index"
          cols="6"
          lg="3"
          md="3"
        >
          <router-link
            :to="'/products/' + item.id"
            style="text-decoration: none"
            class="color_mode"
          >
            <v-card
              elevation="0"
              style="border-radius: 0px !important"
              class="mx-auto bg_mode text-center"
              max-width="400"
            >
              <v-img
                class="white--text mx-auto"
                width="100%"
                height="200"
                :src="uploadPath('/products/' + item.avatar)"
              >
                <!-- https://cdn3d.iconscout.com/3d/premium/thumb/medicine-jar-4036036-3342345.png -->
              </v-img>
              <h2
                class="pb-0 px-2 mt-4 text-truncate"
                style="font-size: 20px; font-weight: 700 !important"
              >
                <span v-if="lang() === 'ku'">{{ item.ku_name }}</span>
                <span v-if="lang() === 'ar'">{{ item.ar_name }}</span>
                <span v-if="lang() === 'en'">{{ item.en_name }}</span>
              </h2>
              <v-card-subtitle class="pb-0" style="font-size: 16px">
                <span v-if="lang() === 'ku'">{{
                  item.productcategories_ku_name
                }}</span>
                <span v-if="lang() === 'ar'">{{
                  item.productcategories_ar_name
                }}</span>
                <span v-if="lang() === 'en'">{{
                  item.productcategories_en_name
                }}</span>
              </v-card-subtitle>

              <v-card-actions>
                <!-- <v-btn color="primary" class="mx-auto mb-3 mt-2" small><v-icon>mdi mdi-chevron-left</v-icon></v-btn> -->
                <v-btn
                  :to="'/products/' + item.id"
                  text
                  class="mx-auto mb-3 mt-2 text-capitalize"
                  small
                  >{{ langkeyword("READ_MORE") }}
                  <v-icon class="mx-1" color="#01994C"
                    >mdi mdi-chevron-right-circle</v-icon
                  ></v-btn
                >

                <div class="mb-2"></div>
              </v-card-actions>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
    </v-container>

    <appFooter />
  </div>
</template>


<script>
import { VueAgile } from "vue-agile";
import appFooter from "../../layouts/client/footer.vue";
import axios from "axios";
export default {
  components: {
    agile: VueAgile,
    appFooter,
  },
  data: () => ({
    info: {},
    asNavFor1: [],
    asNavFor2: [],
    size_table_dialog: false,
    options1: {
      dots: false,
      fade: true,
      navButtons: false,
    },

    options2: {
      autoplay: true,
      centerMode: true,
      dots: false,
      navButtons: false,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
          },
        },

        {
          breakpoint: 1000,
          settings: {
            navButtons: true,
          },
        },
      ],
    },
  }),

  mounted() {
    axios
      .post("/app/client/products/getone", {
        getOneProducts: true,
        product_id: this.$route.params.id,
      })
      .then((res) => {
        if (res.data.status == true) {
          if (res.data.data.status === "blog") {
            this.info = res.data.data;
          } else {
            console.log(res.data);
            this.$router.push("/404");
          }
        } else {
          console.log(res.data);
          this.$router.push("/404");
        }
      })
      .catch((err) => {
        console.log("response error " + err);
      });

    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main);
  },
  computed: {
    products() {
      return this.$store.state.products.filter((entry) => {
        return entry.status === "blog";
      }).slice(0, 3);
    },
    productgalaries() {
      return this.$store.state.productgalaries.filter((entry) => {
        return (
          entry.products_status === "blog" &&
          entry.product_id == this.$route.params.id
        );
      });
    },
  },

  methods: {
    decrement_product() {
      if (this.product_qty > 0) {
        this.product_qty--;
      }
    },
    increment_product() {
      this.product_qty++;
    },
  },
};
</script>


<style>
* {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}
</style>
