<template>
  <div>
    <v-container>
      <v-row class="d-flex justify-center mb-7 mt-10">
        <h2 class="color-gold">{{ langkeyword("LOGIN") }}</h2>
      </v-row>

      <v-row class="d-flex justify-center">
        <v-col cols="12" lg="9" md="9">
          <div
            class="d-flex flex-wrap justify-space-between align-center bg_mode pa-13 rounded-lg"
          >
            <v-col cols="11" lg="3" md="3" class="text-center">
              <v-img
                min-width="300"
                class="mx-auto"
                src="../../../assets/img/login_client.svg"
              ></v-img>
            </v-col>

            <v-col cols="12" lg="5" md="6">
              <Center>
                <span class="orange--text rabar-font" color="primary">{{
                  error_auth
                }}</span>
              </Center>

              <v-row align="center" ali class="d-flex justify-center mt-10">
                <v-text-field
                  :label="this.langkeyword('EMAIL')"
                  filled
                  v-model="username"
                  rounded
                  dense
                  append-icon="mdi-email"
                  icon
                ></v-text-field>
              </v-row>
              <v-row align="center" ali class="d-flex justify-center mt-4">
                <v-text-field
                  filled
                  rounded
                  class="mb-14 rabar-font"
                  v-model="password"
                  :append-icon="
                    show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                  "
                  :label="langkeyword('PASSWORD')"
                  name="password"
                  :type="show ? 'input' : 'password'"
                  hide-details="auto"
                  @click:append="show = !show"
                  ref="pass"
                />
              </v-row>

              <v-row align="center" ali class="d-flex justify-center mt-4">
                <v-btn @click="login" color="primary">{{
                  langkeyword("LOGIN")
                }}</v-btn>
              </v-row>

              <v-row align="center" ali class="d-flex justify-center mt-6">
                <v-btn
                  class="text-forget rabar-font letter-spacing-0"
                  color="orange"
                  text
                  @click="$router.push('/forget')"
                >
                  {{ langkeyword("FORGET_PASSWORD") }} ?
                </v-btn>
              </v-row>

              <v-row align="center" ali class="d-flex justify-center mt-6">
                <v-btn
                  class="text-forget rabar-font letter-spacing-0"
                  text
                  @click="$router.push('/register')"
                >
                  {{ langkeyword("DONTHAVE_AN_ACCOUNT") }}
                </v-btn>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import loginRequests from "../../../requests/client/auth/Login";
export default {
  data() {
    return {
      show: false,
      error: null,
      passErr: null,
      disabled: false,
      username: localStorage.getItem("clientauth-username"),
      password: localStorage.getItem("clientauth-password"),
      num: 0,
      error_auth: null,
    };
  },
  mounted(){
    this.ifAuthorized()
  },
  methods: {
    ifAuthorized() {
      loginRequests.ifAuthorized(
        {
          isAuthCheck: true,
          account_id: this.clientAuthId(),
          token: this.clientAuthToken(),
        },
        (res) => {
          if (res.data.status === true) {
            this.$router.push('/MyProfile')
          }
        }
      );
    },
    login() {
      loginRequests.authLogin(
        {
          isLogin: true,
          user: this.username,
          pass: this.password,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_auth = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            localStorage.setItem("clientauthId", res.data.data.id);
            localStorage.setItem("clientauthToken", res.data.token);
            localStorage.setItem("clientauth-username", this.username);
            localStorage.setItem("clientauth-password", this.password);
            window.location.href="/myprofile"
          //  this.$router.push("/MyProfile");
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>