<template>
  <div>
    <v-container class="show_in_desktop">
      <v-row
        class="d-flex mt-16 bg_mode pa-3"
        style="border-left: 2px solid #01994c; border-right: 2px solid #01994c"
      >
        <h2>
          <span v-if="lang() === 'ku'">{{ info.ku_title }}</span>
          <span v-if="lang() === 'ar'">{{ info.ar_title }}</span>
          <span v-if="lang() === 'en'">{{ info.en_title }}</span>
        </h2>
      </v-row>
    </v-container>

    <v-container class="show_in_desktop">
      <v-row class="mt-2">
        <p class="color-main">
          {{ langkeyword("SERVICETYPE") }}:

          <span v-if="lang() === 'ku'">{{ info.servicetypesku_name }}</span>
          <span v-if="lang() === 'ar'">{{ info.servicetypesar_name }}</span>
          <span v-if="lang() === 'en'">{{ info.servicetypesen_name }}</span>
        </p>
      </v-row>
    </v-container>

    <v-container class="show_in_mobile">
      <v-row class="d-flex justify-center mt-16 pa-1">
        <h2>

          <span v-if="lang() === 'ku'">{{ info.ku_title }}</span>
          <span v-if="lang() === 'ar'">{{ info.ar_title }}</span>
          <span v-if="lang() === 'en'">{{ info.en_title }}</span>

        </h2>
      </v-row>
    </v-container>

    <v-container class="show_in_mobile pa-8">
      <v-row class="mt-2">
        <p class="color-main">
          {{ langkeyword("SERVICETYPE") }}:

          <span v-if="lang() === 'ku'">{{ info.servicetypesku_name }}</span>
          <span v-if="lang() === 'ar'">{{ info.servicetypesar_name }}</span>
          <span v-if="lang() === 'en'">{{ info.servicetypesen_name }}</span>
        </p>
      </v-row>
    </v-container>

    <v-container class="mt-8 pa-8">
      <v-row>
        <h2>{{ langkeyword("DESC") }}</h2>

        <p class="text-justify mt-8">
          <span v-if="lang() === 'ku'">{{ info.ku_detail }}</span>
          <span v-if="lang() === 'ar'">{{ info.ar_detail }}</span>
          <span v-if="lang() === 'en'">{{ info.en_detail }}</span>
        </p>
      </v-row>

      <v-row >
              <img :src="uploadPath('/services/' + info.avatar)" class="mx-auto px-5" width="100%" height="100%"  />
            <template slot="prevButton"
              ><i class="fas fa-chevron-left"></i></template
            ><template slot="nextButton"
              ><i class="fas fa-chevron-right"></i
            ></template>
      </v-row>
    </v-container>

    <v-container class="mt-6 mb-10 ">
      <v-row
        class="d-flex justify-space-between pa-5"
        align="center"
        style="align-items: center !important"
      >
        <span class="mx-2">{{ langkeyword("RELATED_SERVICES") }}</span>
        <v-btn outlined class="rounded-xl text-capitalize">{{
          langkeyword("SHOW_MORE")
        }}</v-btn>
      </v-row>

      <v-row class="d-flex justify-center mt-10">
        <v-col
          v-for="(item, index) in services"
          :class="(item.servicetype_id === info.servicetype_id && item.id != info.id) ? '' :'d-none'"
          :key="index"
          cols="6"
          lg="3"
          md="3"
        > 
          <router-link
            
            :to="'/services/' + item.id"
            style="text-decoration: none"
            class="color_mode"
          >
            <v-card
              style="border-radius: 0px !important"
              max-width="300"
              elevation="0"
              max-height="700"
              class="mx-auto bg_mode text-center"
            >
              <v-img
                class="white--text mx-auto"
                width="100%"
                height="200"
                :src="uploadPath('/services/' + item.avatar)"
              >
              </v-img>
             

              <h2
                class="pb-0 px-2 mt-10 text-truncate"
                style="font-size: 20px; font-weight: 700 !important"
              >
                <span v-if="lang() === 'ku'">{{ item.ku_title }}</span>
                <span v-if="lang() === 'ar'">{{ item.ar_title }}</span>
                <span v-if="lang() === 'en'">{{ item.en_title }}</span>
              </h2>
              <v-card-subtitle
                class="pb-0"
                style="font-size: 16px; height: 100px; overflow: hidden"
              >
                <span v-if="lang() === 'ku'">{{ item.ku_detail }}</span>
                <span v-if="lang() === 'ar'">{{ item.ar_detail }}</span>
                <span v-if="lang() === 'en'">{{ item.en_detail }}</span>
              </v-card-subtitle>

              <v-card-actions>
                <v-btn
                  :to="'/services/' + item.id"
                  text
                  class="mx-auto mb-3 mt-2 text-capitalize"
                  small
                  >{{ langkeyword("READ_MORE") }}
                  <v-icon class="mx-1" color="#01994C"
                    >mdi mdi-chevron-right-circle</v-icon
                  ></v-btn
                >

                <div class="mb-2"></div>
              </v-card-actions>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
    </v-container>

    <appFooter />
  </div>
</template>
  
  
  <script>
import appFooter from "../../layouts/client/footer.vue";
import axios from "axios";

export default {
  components: {
    appFooter,
  },
  data: () => ({
    info: {},
    asNavFor1: [],
    asNavFor2: [],
  }),

  mounted() {
    axios
      .post("/app/client/services/getone", {
        getOneServices: true,
        service_id: this.$route.params.id,
      })
      .then((res) => {
        if (res.data.status == true) {
          this.info = res.data.data;
        } else {
          console.log(res.data);
          this.$router.push("/404");
        }
      })
      .catch((err) => {
        console.log("response error " + err);
      });

    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main);
  },

  computed: {
    services() {
      return this.$store.state.services.slice(0, 4);
    },
  },

  methods: {
    decrement_product() {
      if (this.product_qty > 0) {
        this.product_qty--;
      }
    },
    increment_product() {
      this.product_qty++;
    },
  },
};
</script>
  
  
  <style>
* {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}
</style>
  