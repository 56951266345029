<template>
  <div>
    <navbar></navbar>
    <v-container fluid class="mt-4 mb-4">
      <v-row class="alert mt-4">
        <v-col
          class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
          v-if="error_msg != null"
        >
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col
          class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
          v-if="success_msg != null"
        >
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>

      <v-row class="d-flex text--purple justify-center">
        <v-col cols="11">
          <v-data-table
            :headers="shownHeaders"
            :items="orderitems"
            :loading="loading"
            :search="search"
            sort-by="name"
            class="elevation-1 rabar-font radius-15"
            :footer-props="{
              showFirstLastPage: true,
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-text': langkeyword('PERPAGE'),
              'items-per-page-options': perPages(),
            }"
            :items-per-page="10"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title
                  ><v-icon>mdi-palette</v-icon>
                  <span>
                    {{ langkeyword("ORDER") + ":  " + info.ordercode }}
                  </span>
                </v-toolbar-title>
                <v-toolbar-title
                  ><v-icon>mdi-palette</v-icon>
                  <span>
                    {{ langkeyword("TOTAL") + ":  " + total }} $
                  </span>
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="700px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="success"
                      style="color: whitefloat:right"
                      class="d-none mb-2 radius-5 rabar-font"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-plus-circle </v-icon>
                      {{ langkeyword("ADD") }}
                    </v-btn>
                  </template>
                  <v-card class="radius-15 bg-mode">
                    <v-card-title>
                      <span class="rabar-font" v-if="isAdd() === true"
                        ><v-icon> mdi-plus-circle</v-icon>
                        {{ langkeyword("ADD") }}</span
                      >
                      <span class="rabar-font" v-if="isEdit() === true"
                        ><v-icon> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}</span
                      >
                    </v-card-title>
                    <v-card-text class="radius-15">
                      <v-container>
                        <v-form> </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="green darken-1"
                        v-if="isAdd() === true"
                        dark
                        @click="saveOrderItems"
                      >
                        <v-icon dark> mdi-plus-circle</v-icon>
                        {{ langkeyword("SAVE") }}
                      </v-btn>
                      <v-btn
                        color="orange darken-1"
                        v-if="isEdit() === true"
                        dark
                        @click="updateOrderItems(orderitemsObject.id)"
                      >
                        <v-icon dark> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" dark @click="closeMainDialog">
                        <v-icon dark>mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <v-icon>mdi-delete-circle</v-icon>
                      {{ langkeyword("DELETE") }}
                    </v-card-title>
                    <v-card-text>
                      {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="red lighten-2"
                        dark
                        @click="deleteOrderItems(orderitemsObject.id)"
                      >
                        <v-icon>mdi-delete-circle</v-icon>
                        {{ langkeyword("DELETE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        justify="end"
                        color="red darken-2"
                        dark
                        @click="closeDeleteDialog"
                      >
                        <v-icon> mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>

                <v-row>
                  <v-col cols="12" md="10">
                    <v-text-field
                      class="rabar-font"
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="langkeyword('SEARCH')"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>
  
  <script>
import Vue from "vue";
import OrderItemsRequests from "../../../requests/dashboard/order/OrderItems";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
import axios from "axios";
export default {
  components: {
    navbar,
    dashboardFooter,
  },
  data() {
    return {
      info: {},
      total: 0,
      dialog: false,
      dialogDelete: false,
      snackbar: true,
      orderitems: [],
      search: "",
      loading:
        Vue.prototype.getters().getorderitems == null ||
        Vue.prototype.getters().getorderitems == "" ||
        Vue.prototype.getters().getorderitems == undefined
          ? true
          : false,
      error_msg: null,
      success_msg: null,
      headers: [
        { text: "#", value: "id", align: "center", show: true },
        {
          text: Vue.prototype.langkeyword("PRODUCT"),
          value: "products_" + Vue.prototype.langoptions("value") + "_name",
          align: "center",
          show: true,
        },
        {
          text: Vue.prototype.langkeyword("COLOR"),
          value: "colors_" + Vue.prototype.langoptions("value") + "_name",
          align: "center",
          show: true,
        },
        {
          text: Vue.prototype.langkeyword("SIZE"),
          value: "sizes_" + Vue.prototype.langoptions("value") + "_name",
          align: "center",
          show: true,
        },
        {
          text: Vue.prototype.langkeyword("PRICE"),
          value: "price",
          align: "center",
          show: true,
        },
        {
          text: Vue.prototype.langkeyword("QUANTITY"),
          value: "qty",
          align: "center",
          show: true,
        },
        {
          text: Vue.prototype.langkeyword("SUBTOTAL"),
          value: "subtotal",
          align: "center",
          show: true,
        },
      ],
      CURRENTFORM: "add",
      orderitemsObject: {},
    };
  },
  watch: {
    options: {
      handler() {
        this.readData();
      },
    },
  },
  computed: {
    shownHeaders() {
      return this.headers.filter((h) => {
        if (h.show) {
          return h;
        }
      });
    },
  },
  mounted() {
    axios
      .post("/app/dashboard/orders/getone", {
        getOneOrders: true,
        order_id: this.$route.params.id,
      })
      .then((res) => {
        if (res.data.status == true) {
          this.info = res.data.data;
          this.total = res.data.total;
          this.readData();
        } else {
          console.log(res.data);
          this.$router.push("/404");
        }
      })
      .catch((err) => {
        console.log("response error " + err);
      });

    this.readData();
  },
  methods: {
    isAdd() {
      if (this.CURRENTFORM === "add") {
        return true;
      } else {
        return false;
      }
    },
    isEdit() {
      if (this.CURRENTFORM === "edit") {
        return true;
      } else {
        return false;
      }
    },
    cleanMessages() {
      this.orderitemsObject = {
        user_id: Vue.prototype.dashboardAuthId(),
        order_id: this.$route.params.id,
      };
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    validate(params, callback) {
      this.error_msg = null;
      params.forEach((param) => {
        if (param == "" || param == undefined || param == null) {
          this.error_msg = this.langkeyword("DATA_REQUIRED");
        }
      });
      if (this.error_msg == null) {
        return callback();
      }
      this.cleanMessages();
    },
    editDialog(item) {
      this.CURRENTFORM = "edit";
      this.orderitemsObject = Object.assign({}, item);
      this.dialog = true;
    },
    lockDialog(item) {
      this.CURRENTFORM = "lock";
      this.orderitemsObject = Object.assign({}, item);
      this.dialog = true;
    },
    deleteDialog(item) {
      this.CURRENTFORM = "delete";
      this.orderitemsObject = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeMainDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.orderitemsObject = Object.assign({}, this.defaultItem);
        this.CURRENTFORM = "add";
      });
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.orderitemsObject = Object.assign({}, this.defaultItem);
        this.CURRENTFORM = "add";
      });
    },
    readData() {
      this.orderitems = this.$store.getters.getorderitems.filter((pc) => {
        return pc.order_id == this.$route.params.id;
      });
    },
    deleteOrderItems(orderitemId) {
      OrderItemsRequests.delete(
        { deleteOrderItems: true, orderitem_id: orderitemId },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.orderitems = res.data.data;
            this.orderitems = res.data.data;
          }
        }
      );
      this.cleanMessages();
      this.closeDeleteDialog();
    },
  },
};
</script>
  