// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
          light: {
            primary: "#01994C",
         
          },

          dark: {
            primary: "#01994C",
         
          }

        }
      }
}

export default new Vuetify(opts)