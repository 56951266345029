<template>
  <div>
    <!-- <navbar/> -->

    <v-container class="show_in_desktop">
      <v-row
        class="d-flex justify-center mt-16 bg_mode pa-3"
        style="border-left: 2px solid #01994c; border-right: 2px solid #01994c"
      >
        <h1 class="color-main">{{ langkeyword("PROJECTS") }}</h1>
      </v-row>
    </v-container>

    <v-container class="show_in_mobile">
      <v-row class="d-flex justify-center mt-16 pa-3 bg_mode">
        <h1 class="color-main">{{ langkeyword("PROJECTS") }}</h1>
      </v-row>
    </v-container>

    <!-- items -->
    <div class="mt-6 mb-10">
      <v-row>
        <v-col cols="12" lg="6" sm="8" class="mx-auto text-center">
          <v-text-field
            class="mx-auto text-center bg_mode rounded px-2"
            :label="langkeyword('SEARCH')"
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="pa-lg-6 d-flex flex-wrap">
        <v-col cols="12" sm="8" md="3" lg="3" class="mx-auto text-center pa-10">
          <v-list class="pa-3 mt-2">
            <v-list-group :value="true">
              <template v-slot:activator>
                <v-list-item-title>{{
                  langkeyword("PROJECT_CATEGORY")
                }}</v-list-item-title>
              </template>

              <v-list flat>
                <v-list-item
                  v-for="(item, index) in projectcategories"
                  :key="index"
                >
                <v-checkbox
                    multiple
                    :value="item.id"
                    v-model="projectcategories_filter"
                    v-if="lang() === 'ku'" :label="item.ku_name"
                  ></v-checkbox>                  
                  <v-checkbox
                    multiple
                    :value="item.id"
                    v-model="projectcategories_filter"
                    v-if="lang() === 'ar'"
                    :label="item.ar_name"
                  ></v-checkbox>
                  <v-checkbox
                    multiple
                    :value="item.id"
                    v-model="projectcategories_filter"
                    v-if="lang() === 'en'"
                    :label="item.en_name"
                  ></v-checkbox>
                </v-list-item>
              </v-list>
            </v-list-group>
          </v-list>
        </v-col>

        <v-col cols="12" md="9" sm="12" lg="9">
          <v-row
            align="center"
            class="d-flex px-8 justify-space-between"
            style="align-items: center !important"
          >
            <div>
              <p style="font-size: 20px !important">{{langkeyword('PROJECTS')}}</p>
            </div>

            <div class="d-flex justify-space-around">
              <v-select
                :items="[10,15,20,50,100]"
                v-model="itemnumber"
                dense
                outlined
                hide-details
                style="width: 80px"
              ></v-select>
            </div>
            
          </v-row>

          <!-- card items   -->
          <v-row class="d-flex justify-center flex-wrap pa-2">
            <v-row class="d-flex justify-center" v-if="projects.length == 0">
              <h3>{{ langkeyword("NOTFOUND") }}</h3>
            </v-row>
            <v-col
              v-for="(item, index) in projects"
              :key="index"
              cols="6"
              lg="4"
              md="4"
            >
              <router-link
                :to="'/projects/' + item.id"
                style="text-decoration: none !important"
              >
                <v-card
                  elevation="0"
                  style="border-radius: 0px !important"
                  class="mx-auto bg_mode"
                  max-width="300"
                >
                  <v-img
                    class="white--text mx-auto"
                    width="100%"
                    height="200"
                    :src="uploadPath('/projects/' + item.avatar)"
                  >
                  </v-img>
                  <v-card-subtitle
                    class="pb-0 text-center"
                    style="font-size: 16px"
                  >
                    <span v-if="lang() === 'ku'">{{ item.ku_title }}</span>
                    <span v-if="lang() === 'ar'">{{ item.ar_title }}</span>
                    <span v-if="lang() === 'en'">{{ item.en_title }}</span>
                  </v-card-subtitle>

                  <v-card-actions>
                    <!-- <v-btn color="primary" class="mx-auto mb-3" small><v-icon>mdi mdi-cart-plus</v-icon></v-btn>
         -->
                    <div class="mb-2"></div>
                  </v-card-actions>
                </v-card>
              </router-link>
            </v-col>
          </v-row>
          <!-- end card items -->

          <v-row class="mb-16"> </v-row>

 
        </v-col>
      </v-row>
    </div>

    <appFooter />
  </div>
</template>
  
  <script>
// import navbar from "../../../layouts/client/nav.vue";
import appFooter from "../../layouts/client/footer.vue";
export default {
  components: { appFooter },
  data() {
    return {
      projectcategories_filter: [],
      itemnumber: 10,
      search:'',
    };
  },
  computed: {
    projectcategories() {
      return this.$store.state.projectcategories;
    },
    projects() {

       if (this.projectcategories_filter.length === 0) {
        return this.$store.state.projects.filter((entry)=>{
            return (entry.en_title.toLowerCase().includes(this.search.toLowerCase()) || entry.ar_title.toLowerCase().includes(this.search.toLowerCase()) || entry.ku_title.toLowerCase().includes(this.search.toLowerCase()));
          }).slice(0,this.itemnumber);
      }else{
   
          return this.$store.state.projects.filter((entry)=>{
            return ( this.projectcategories_filter.includes(entry.projectcategory_id) );
          })

        }



      
   
        

          
       
      
    }
  },
  mounted() {
    // this.add_to_favorite()
  },
  methods: {
    // add_filter(index){
    //   this.amenities.push(index);
    // }
    add_to_favorite() {
      console.log(this.projectcategories)
    },
  },
};
</script>
  
  <style scoped>
</style>