<template>
  <div>
    <!-- slider -->

    <slider></slider>

    <v-container class="mt-10 mb-10">
      <v-row class="d-flex justify-center">
        <h2 class="color_mode">{{ this.langkeyword("OUR_PRODUCTS") }}</h2>
      </v-row>

      <v-row class="d-flex justify-center">
        <v-col
          v-for="(item, index) in products"
          :key="index"
          cols="6"
          lg="3"
          md="3"
        >
          <router-link
          :to="'/products/' + item.id"
            style="text-decoration: none"
            class="color_mode"
          >
            <v-card
              style="border-radius: 0px !important"
              elevation="4"
              class="mx-auto bg_mode text-center"
              max-width="400"
            >
              <v-img
                class="white--text mx-auto"
                width="100%"
                height="200"
                :src="uploadPath('/products/' + item.avatar)"
              >
              </v-img>
              <h2
                class="pb-0 px-2 mt-4 text-truncate"
                style="font-size: 20px; font-weight: 700 !important"
              >
                <span v-if="lang() === 'ku'">{{ item.ku_name }}</span>
                <span v-if="lang() === 'ar'">{{ item.ar_name }}</span>
                <span v-if="lang() === 'en'">{{ item.en_name }}</span>
              </h2>
              <v-card-subtitle class="pb-0" style="font-size: 16px">
                <span v-if="lang() === 'ku'">{{
                  item.productcategories_ku_name
                }}</span>
                <span v-if="lang() === 'ar'">{{
                  item.productcategories_ar_name
                }}</span>
                <span v-if="lang() === 'en'">{{
                  item.productcategories_en_name
                }}</span>
              </v-card-subtitle>

              <v-card-actions>
                <!-- <v-btn color="primary" class="mx-auto mb-3 mt-2" small><v-icon>mdi mdi-chevron-left</v-icon></v-btn> -->
                <v-btn
                  :to="'/products/' + item.id"
                  text
                  class="mx-auto mb-3 mt-2 text-capitalize"
                  small
                >
                  <v-icon class="mx-1" color="#01994C"
                    >mdi mdi-chevron-right-circle</v-icon
                  ></v-btn
                >

                <div class="mb-2"></div>
              </v-card-actions>
            </v-card>
          </router-link>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center mt-10">
        <v-btn
          to="/products"
          color="#01994C"
          class="rounded-xl mx-auto mb-3 mt-2 text-capitalize"
          style="color: white"
          >{{ langkeyword("ALL_PRODUCTS") }}
          <v-icon class="mx-1">mdi mdi-chevron-right-circle</v-icon></v-btn
        >
      </v-row>

      <v-row class="d-flex justify-center mt-16 mb-10">
        <h2 class="color_mode">{{ this.langkeyword("LAST_PROJECTS") }}</h2>
      </v-row>

      <v-container class="col-11 px-lg-10">
        <VueSlickCarousel :arrows="true" :dots="false" class="card-slider">
          <v-card
          v-for="(item, index) in projects"
            height="480"
            :key="index"
            class="d-flex project_slider bg_mode justify-center flex-wrap"
            style="border-radius: 0px !important"
          >
            <v-col cols="12" md="6" lg="6" class="col-text">
              <v-card-title>
                <h3 class="project_title">
                  <span v-if="lang() === 'ku'">{{ item.ku_title }}</span>
                  <span v-if="lang() === 'ar'">{{ item.ar_title }}</span>
                  <span v-if="lang() === 'en'">{{ item.en_title }}</span>
                </h3>
              </v-card-title>

              <div class="mt-5 pa-4 show_in_desktop">
                <p
                  style="font-size: 35px !important"
                  class="text-maxline text-justify"
                >
                  <span v-if="lang() === 'ku'">{{ item.ku_detail }}</span>
                  <span v-if="lang() === 'ar'">{{ item.ar_detail }}</span>
                  <span v-if="lang() === 'en'">{{ item.en_detail }}</span>
                </p>
              </div>

              <v-card-actions>
                <v-btn
                  :to="'/projects/'+item.id"
                  text
                  class="mx-auto mb-3 mt-2 text-capitalize"
                  small
                >
                  {{ langkeyword("READ_MORE") }}
                  <v-icon class="mx-1" color="#01994C"
                    >mdi mdi-chevron-right-circle</v-icon
                  ></v-btn
                >
              </v-card-actions>
            </v-col>

            <v-col cols="12" md="6" lg="6" class="pa-0">
              <v-img
                height="100%"
                :src="uploadPath('/projects/' + item.avatar)"
              ></v-img>
            </v-col>
          </v-card>
        </VueSlickCarousel>
      </v-container>

      <div class="bg_test show_in_desktop"></div>

      <v-row class="d-flex justify-center mb-10 mt-10">
        <v-btn
          to="/projects"
          color="#01994C"
          class="rounded-xl mx-auto mb-3 mt-2 text-capitalize"
          style="color: white"
          >{{ langkeyword("ALL_PROJECTS") }}
          <v-icon class="mx-1">mdi mdi-chevron-right-circle</v-icon></v-btn
        >
      </v-row>

      <br />

      <v-row class="d-flex justify-center mt-16">
        <h2 class="color_mode mb-3">{{ langkeyword("OUR_SERVICES") }}</h2>
      </v-row>

      <v-container class="col-11 px-lg-10">
        <VueSlickCarousel
          class="text-center"
          :responsive="resp"
          :arrows="true"
          :dots="false"
          :speed="500"
          :slidesToShow="3"
          :slidesToScroll="3"
          :initialSlide="0"

        >
          <v-card
          v-for="(item, index) in services" :key="index"
            style="border-radius: 0px !important"
            max-width="300"
            elevation="0"
            max-height="700"
            class="mx-auto bg_mode text-center"
          >
            <v-img
              class="white--text mx-auto"
              width="100%"
              height="200"
              :src="uploadPath('/services/' + item.avatar)"
              >
              <!-- https://cdn3d.iconscout.com/3d/premium/thumb/medicine-jar-4036036-3342345.png -->
            </v-img>

            <v-btn
              style="position: absolute; top: 175px; left: 120px"
              class="mx-auto bg_mode color-main"
              fab
            >
              <v-icon> mdi-toolbox-outline </v-icon>
            </v-btn>

            <h2
              class="pb-0 px-2 mt-10 text-truncate"
              style="font-size: 20px; font-weight: 700 !important"
            >
            <span v-if="lang() === 'ku'">{{ item.ku_title }}</span>
                  <span v-if="lang() === 'ar'">{{ item.ar_title }}</span>
                  <span v-if="lang() === 'en'">{{ item.en_title }}</span>
            </h2>
            <v-card-subtitle
              class="pb-0"
              style="font-size: 16px; height: 100px; overflow: hidden"
            >
            <span v-if="lang() === 'ku'">{{ item.ku_detail.slice(0,250) }}</span>
                  <span v-if="lang() === 'ar'">{{ item.ar_detail.slice(0,250) }}</span>
                  <span v-if="lang() === 'en'">{{ item.en_detail.slice(0,250) }}</span>
            </v-card-subtitle>

            <v-card-actions>
              <!-- <v-btn color="primary" class="mx-auto mb-3 mt-2" small><v-icon>mdi mdi-chevron-left</v-icon></v-btn> -->
              <v-btn
              :to="'/services/'+item.id"
                text
                class="mx-auto mb-3 mt-2 text-capitalize"
                small
                >{{ langkeyword("READ_MORE") }}
                <v-icon class="mx-1" color="#01994C"
                  >mdi mdi-chevron-right-circle</v-icon
                ></v-btn
              >

              <div class="mb-2"></div>
            </v-card-actions>
          </v-card>
        </VueSlickCarousel>
      </v-container>
    </v-container>

    <div class="col-11 col-lg-12 col-md-12 mx-auto">
      <v-row class="mt-10 mb-10 bg_mode rounded-lg">
        <v-col cols="11" lg="12" md="12">
          <div class="d-flex flex-wrap justify-space-around align-center">
            <v-col cols="12" lg="2" md="2">
              <v-btn
                to="/shop"
                color="#01994C"
                class="white--text rounded-xl pa-6"
                >{{ langkeyword("START_SHOPPING") }}</v-btn
              >
            </v-col>

            <v-col cols="12" lg="3" md="3" class="text-center">
              <v-img class="mx-auto" src="../../assets/img/shop.svg"></v-img>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- end product -->

    <appFooter />
  </div>
</template>

<script>
// import navbar from "../../layouts/client/nav.vue";
import appFooter from "../../layouts/client/footer.vue";
import slider from "../../components/client/slider.vue";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

// import { VueperSlides, VueperSlide } from 'vueperslides'
// import 'vueperslides/dist/vueperslides.css'

export default {
  components: { appFooter, slider, VueSlickCarousel },

  data() {
    return {
      resp: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  },
  computed: {
    services() {
      return this.$store.state.services.slice(0, 6);
    },
    products() {
      return this.$store.state.products.filter(entry=>{
          return entry.status === 'blog'
        }).slice(0, 4);
    },
    projects() {
      return this.$store.state.projects.slice(0, 4);
    },
  },


  mounted(){
    this.$nextTick(() => {
      this.$refs.carousel.init();
    });
  }

};
</script>

<style  scoped>
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>