<template>
  <div>
    <!-- <navbar/> -->

    <v-img
      class="project_images"
      max-width="100%"
      height="600px"
      :src="uploadPath('/projects/' + info.avatar)"
      style="background-size: cover !important"
    >
      <v-sheet
        color="rgba(0, 0, 0, 0.38)"
        elevation="1"
        height="100"
        width="100%"
        style="position:absolute; !important;bottom:0px !important"
        class="d-flex justify-center text-center"
      >
        <p
          style="font-size: 25px; color: white; transform: translate(0px, 30px)"
        >
          <span v-if="lang() === 'ku'">{{ info.ku_title }}</span>
          <span v-if="lang() === 'ar'">{{ info.ar_title }}</span>
          <span v-if="lang() === 'en'">{{ info.en_title }}</span>
        </p>
      </v-sheet>
    </v-img>

    <v-container class="mt-10">
      <v-row class="d-flex justify-center flex-wrap">
        <v-col cols="11" lg="4" md="4">
          <v-card
            elevation="0"
            style="border-radius: 0px !important"
            class="bg_mode pa-4"
            max-width="100%"
          >


          <v-row
              class="d-flex justify-space-between"
              align="center">
              <v-card-title class="pb-0" style="font-size: 16px">
                {{ langkeyword('LOCATION') }}
              </v-card-title>
                
              <v-card-title class="pb-0" style="font-size: 14px">
                  {{ info.location }}
              </v-card-title>
            </v-row>
            <v-row
              class="d-flex justify-space-between"
              align="center">
              <v-card-title class="pb-0" style="font-size: 16px">
                {{ langkeyword('NUMBER_ELEVATOR') }}
              </v-card-title>
               
              <v-card-title class="pb-0" style="font-size: 14px">
                  {{ info.elevator }}
              </v-card-title>
            </v-row>
            <v-row
              class="d-flex justify-space-between"
              align="center">
              <v-card-title class="pb-0" style="font-size: 16px">
                {{ langkeyword('NUMBER_TOWER') }}
              </v-card-title>
                
              <v-card-title class="pb-0" style="font-size: 14px">
                  {{ info.tower }}
              </v-card-title>
            </v-row>
            <v-row
              class="d-flex justify-space-between"
              align="center">
              <v-card-title class="pb-0" style="font-size: 16px">
                {{ langkeyword('NUMBER_FLOOR') }}
              </v-card-title>
                
              <v-card-title class="pb-0" style="font-size: 14px">
                  {{ info.floor }}
              </v-card-title>
            </v-row>
            <v-row
              class="d-flex justify-space-between"
              align="center">
              <v-card-title class="pb-0" style="font-size: 16px">
                {{ langkeyword('SPEED') }}
              </v-card-title>
                
              <v-card-title class="pb-0" style="font-size: 14px">
                  {{ info.speed }}
              </v-card-title>
            </v-row>
            <v-row
              class="d-flex justify-space-between"
              align="center">
              <v-card-title class="pb-0" style="font-size: 16px">
                {{ langkeyword('CLIENT') }}
              </v-card-title>
                
              <v-card-title class="pb-0" style="font-size: 14px">
                  {{ info.clientname }}
              </v-card-title>
            </v-row>
            <v-row
              class="d-flex justify-space-between"
              align="center">
              <v-card-title class="pb-0" style="font-size: 16px">
                {{ langkeyword('CAPACITY') }}
              </v-card-title>
                
              <v-card-title class="pb-0" style="font-size: 14px">
                  {{ info.capacity }}
              </v-card-title>
            </v-row>


          </v-card>
        </v-col>

        <v-col cols="11" lg="7" md="7">
          <h3>{{ langkeyword("DESC") }}</h3>

          <p class="text-justify">
            <span v-if="lang() === 'ku'">{{ info.ku_detail }}</span>
            <span v-if="lang() === 'ar'">{{ info.ar_detail }}</span>
            <span v-if="lang() === 'en'">{{ info.en_detail }}</span>
          </p>
        </v-col>
      </v-row>
    </v-container>

    <v-card max-width="100%" class="mx-auto mt-10" elevation="0">
      <v-container class="pt-10">
        <v-row class="d-flex justify-center mb-8">
          <h3>{{ langkeyword("GALLERY") }}</h3>
        </v-row>

        <v-item-group v-model="selected" multiple>
          <v-row>
            <v-col v-for="(item, index) in projectavatars" :key="index" cols="6" md="6" lg="6">
              <v-item >
                <v-img
                :src="uploadPath('/projectavatars/' + item.avatar)"
                  class="text-right pa-2"
                >
                </v-img>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-container>
    </v-card>

    <appFooter />
  </div>
</template>

<script>
// import navbar from "../../../layouts/client/nav.vue";
import axios from "axios";
import appFooter from "../../layouts/client/footer.vue";
export default {
  components: { appFooter },
  data() {
    return {
      info: {},
    };
  },

  mounted() {
    axios
      .post("/app/client/projects/getone", {
        getOneProjects: true,
        project_id: this.$route.params.id,
      })
      .then((res) => {
        
        if (res.data.status == true) {
          this.info = res.data.data;
        } else {
          console.log(res.data);
          this.$router.push("/404");
        }
      })
      .catch((err) => {
        console.log("response error " + err);
      });


console.log(this.projectavatars)
      
  },

  computed: {
    projects() {
      return this.$store.state.projects.slice(0, 4);
    },
    projectavatars() {
      return this.$store.state.projectavatars.filter(entry=>{
        console.log(this.info)
        return this.info.id === entry.project_id
      });
    },
  },

  methods: {
    // add_filter(index){
    //   this.amenities.push(index);
    // }
    add_to_favorite() {},
  },
};
</script>

<style scoped>
</style>