<template>
    <div>
        <v-container>
            
<!-- <v-row class="d-flex justify-center mb-7 mt-10 ">
    <h2 class="color-gold">{{langkeyword('LOGIN')}}</h2>
 </v-row>    -->

<v-row class="d-flex justify-center mt-10">
    

        <v-col
          cols="12"
          lg="9"
          md="9"
        >

       
            <div class="d-flex flex-wrap justify-space-between align-center bg_mode pa-13 rounded-lg" >

                <v-col cols="11" lg="3" md="3" class="text-center">

                    <center><span class="orange--text rabar-font" color="primary">{{error_auth}}</span></center>
                <v-img  min-width="250" class="mx-auto" src="../../../assets/img/forget.svg"></v-img>
       
            </v-col>

            
                <v-col cols="12" lg="5" md="6">
                    <v-row align="center" ali class="d-flex justify-center mt-10">

                        <v-text-field
                                :label="this.langkeyword('EMAIL')"
                                filled
                                rounded 
                                dense
                                v-model="email"
                                append-icon="mdi-email"
                                icon
                            ></v-text-field>
                    </v-row>
                 
                    <v-row align="center" ali class="d-flex justify-center mt-4">

                        <v-btn small @click="forget" color="primary">{{langkeyword('AFTER')}}</v-btn>
                        
                    </v-row>

                    
                   <v-row align="center" ali class="d-flex justify-center mt-6">
                    <v-btn class="text-forget rabar-font letter-spacing-0" 
                                    color="orange" text @click="$router.push('/login')">
                                    {{langkeyword('BACK_TO_LOGIN')}} 
                                </v-btn>
                   </v-row>

                         
                </v-col>
  
    
            </div>
      
        </v-col>
      </v-row>

        </v-container>
    </div>
</template>

<script>
import forgetRequests from "../../../requests/client/auth/Forget";
    export default {
        data() {
            return {
                show: false,
                error: null,
                disabled: false,
                email: '',
                error_auth: null,
            }
        },
        methods: {
    ifAuthorized(){
    forgetRequests.ifAuthorized({
    isAuthCheck:true,
    user_id:this.clientAuthId(),
    token:this.clientAuthToken(),
  },(res)=>{
    if (res.data.status===true){
this.$router.push('/MyProfile')
    }
  })
      },
    forget() {
 if(this.email!=null) {
 forgetRequests.authForget({
    isForget:true,
    email:this.email,
  },(res)=>{
    if (res.data.status===false){
this.error_auth = this.langkeyword(res.data.msg)
    }
    else if (res.data.status===true) {
    this.$router.push('/reset?token='+res.data.token)
    }
  })
 }
            },
        },
        mounted(){
        this.ifAuthorized()
        }




    }
</script>

<style lang="scss" scoped>

</style>