import Vue from 'vue'
import VueRouter from 'vue-router'


// client
import clienthome from '../pages/client/home.vue'
import clientabout from '../pages/client/about.vue'
import clientcontact from '../pages/client/contact.vue'
import clientCart from '../pages/client/cart.vue'
import clientOrders from '../pages/client/orders.vue'
import clientViewOrder from '../pages/client/view_order.vue'
import clientViewProduct from '../pages/client/view_product.vue'
import clientProducts from '../pages/client/products.vue'
import clientShop from '../pages/client/shop.vue'
import clientProjects from '../pages/client/projects.vue'
import clientViewProject from '../pages/client/view_project.vue'
import clientShopProduct from '../pages/client/view_shop_product.vue'
import clientLogin from '../pages/client/auth/login.vue'   
import clientRegister from '../pages/client/auth/register.vue'   
import clientForget from '../pages/client/auth/forget.vue'   
import clientReset from '../pages/client/auth/reset.vue'   
import clientSetting from '../pages/client/setting.vue'
import clientServices from '../pages/client/services.vue'
import clientViewServices from '../pages/client/view_services.vue'
import clientProfile from '../pages/client/profile.vue'
//////////////////////////////////////////////////////////////////////////////////////////////////////////////







// dashboard
import dashboardhome from '../pages/dashboard/home.vue'
import dashboardauth_login from '@/pages/dashboard/auth/login.vue'
import dashboardauth_forget from '@/pages/dashboard/auth/forget.vue'
import dashboardauth_reset from '@/pages/dashboard/auth/reset.vue'
import users from '@/pages/dashboard/users.vue'
import dashboardprofile from '@/pages/dashboard/profile.vue'

import setting_appearances from '../pages/dashboard/setting/appearances.vue'
import setting_metas from '@/pages/dashboard/setting/metas.vue'
import setting_socials from '@/pages/dashboard/setting/socials.vue'
import setting_contacts from '@/pages/dashboard/setting/contacts.vue'



import dashboardproduct_stores from '@/pages/dashboard/product/stores.vue'
import dashboardproduct_productcategories from '@/pages/dashboard/product/productcategories.vue'
import dashboardproduct_brands from '@/pages/dashboard/product/brands.vue'
import dashboardproduct_sliders from '@/pages/dashboard/product/sliders.vue'
import dashboardproduct_products from '@/pages/dashboard/product/products.vue'
import dashboardproduct_product_colors from '@/pages/dashboard/product/product_colors.vue'
import dashboardproduct_product_colors_sizes from '@/pages/dashboard/product/product_sizes.vue'
import dashboardproduct_product_colors_avatars from '@/pages/dashboard/product/product_avatars.vue'
import dashboardproduct_product_galaries from '@/pages/dashboard/product/product_galaries.vue'

import dashboardproduct_colors from '@/pages/dashboard/product/colors.vue'
import dashboardproduct_sizes from '@/pages/dashboard/product/sizes.vue'


import dashboardproject_projectcategories from '@/pages/dashboard/project/projectcategories.vue'
import dashboardproject_projects from '@/pages/dashboard/project/projects.vue'
import dashboardproject_project_view from '@/pages/dashboard/project/project_view.vue'

import dashboardservice_servicetypes from '@/pages/dashboard/service/servicetypes.vue'
import dashboardservice_services from '@/pages/dashboard/service/services.vue'

import dashboardorder_orders from '@/pages/dashboard/order/orders.vue'
import dashboardorder_order_view from '@/pages/dashboard/order/order_view.vue'


import notFound from '@/pages/404.vue'
Vue.use(VueRouter)






const routes = [
  {
    path: '*',
    name: 'notFound',
    component: notFound,
    meta: {
      hideNavbar: true,
     }
  },
  








  

  // dashboard
  {
    path: '/dashboard/home',
    name: 'DashboardHome',
    component: dashboardhome,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/',
    name: 'DashboardRootHome',
    component: dashboardhome,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/auth/login',
    name: 'DashboardAuthLogin',
    component: dashboardauth_login,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/auth/forget',
    name: 'DashboardAuthForget',
    component: dashboardauth_forget,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/auth/reset',
    name: 'DashboardAuthReset',
    component: dashboardauth_reset,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/profile',
    name: 'DashboardProfile',
    component: dashboardprofile,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/users',
    name: 'DashboardUsers',
    component: users,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/setting/appearances',
    name: 'DashboardSettingAppearances',
    component: setting_appearances,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/setting/metas',
    name: 'DashboardSettingMetas',
    component: setting_metas,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/setting/socials',
    name: 'DashboardSettingSocials',
    component: setting_socials,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/setting/contacts',
    name: 'DashboardSettingContacts',
    component: setting_contacts,
    meta: {
      hideNavbar: true,
     }
  },


  {
    path: '/dashboard/product/stores',
    name: 'DashboardProductStores',
    component: dashboardproduct_stores,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/product/productcategories',
    name: 'DashboardProductProductCategories',
    component: dashboardproduct_productcategories,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/product/brands',
    name: 'DashboardProductBrands',
    component: dashboardproduct_brands,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/product/sliders',
    name: 'DashboardProductSliders',
    component: dashboardproduct_sliders,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/product/products',
    name: 'DashboardProductProducts',
    component: dashboardproduct_products,
    meta: {
      hideNavbar: true,
     }
  },

  { 
    path: '/dashboard/product/products/:id/colors',
    name: 'DashboardProductProductColors',
    component: dashboardproduct_product_colors,
    meta: {
      hideNavbar: true,
     }
  },

  {
    path: '/dashboard/product/products/:id/colors/:colorid/sizes',
    name: 'DashboardProductProductColorSizes',
    component: dashboardproduct_product_colors_sizes,
    meta: {
      hideNavbar: true,
     }
  },



  {
    path: '/dashboard/product/products/:id/colors/:colorid/avatars',
    name: 'DashboardProductProductColorAvatars',
    component: dashboardproduct_product_colors_avatars,
    meta: {
      hideNavbar: true,
     }
  },



  { 
    path: '/dashboard/product/products/:id/galaries',
    name: 'DashboardProductProductGalaries',
    component: dashboardproduct_product_galaries,
    meta: {
      hideNavbar: true,
     }
  },




  {
    path: '/dashboard/product/colors',
    name: 'DashboardProductColors',
    component: dashboardproduct_colors,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/product/sizes',
    name: 'DashboardProductSizes',
    component: dashboardproduct_sizes,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/service/servicetypes',
    name: 'DashboardServiceServiceTypes',
    component: dashboardservice_servicetypes,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/service/services',
    name: 'DashboardServiceServices',
    component: dashboardservice_services,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/project/projectcategories',
    name: 'DashboardProjectProjectCategories',
    component: dashboardproject_projectcategories,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/project/projects',
    name: 'DashboardProjectProjects',
    component: dashboardproject_projects,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/project/projects/:id',
    name: 'DashboardProjectProjectView',
    component: dashboardproject_project_view,
    meta: {
      hideNavbar: true,
     }
  },

  {
    path: '/dashboard/order/orders',
    name: 'DashboardOrderOrders',
    component: dashboardorder_orders,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/dashboard/order/orders/:id',
    name: 'DashboardOrderOrderView',
    component: dashboardorder_order_view,
    meta: {
      hideNavbar: true,
     }
  },




// client
  {
    path: '/',
    name: 'ClientHome',
    component: clienthome
  },
  {
    path: '/about',
    name: 'ClientAbout',
    component:clientabout
  },
  {
    path: '/contact',
    name: 'ClientContact',
    component:clientcontact
  }, 
  
  {
    path: '/cart',
    name: 'clientCart',
    component:clientCart
  }, 
  {
    path: '/orders',
    name: 'clientOrders',
    component:clientOrders
  },
  {
    path: '/orders/:id',
    name: 'clientViewOrder',
    component:clientViewOrder
  }, 
  {
    path: '/products/:id',
    name: 'clientViewProduct',
    component:clientViewProduct
  }, 

  {
    path: '/login',
    name: 'clientLogin',
    component:clientLogin
  },
  {
    path: '/register',
    name: 'clientRegister',
    component:clientRegister
  }, 
  {
    path: '/forget',
    name: 'clientForget',
    component:clientForget
  }, 
  {
    path: '/reset',
    name: 'clientReset',
    component:clientReset
  },  
  {
    path: '/products',
    name: 'clientProducts',
    component:clientProducts
  }, 
  {
    path: '/shop',
    name: 'clientShop',
    component:clientShop
  }, 
  {
    path: '/setting',
    name: 'clientSetting',
    component:clientSetting
  }, 
  
  {
    path: '/projects',
    name: 'clientProjects',
    component:clientProjects
  }, 
  
  {
    path: '/shop/:id',
    name: 'clientShopProduct',
    component:clientShopProduct
  }, 
  
  {
    path: '/projects/:id',
    name: 'clientViewProject',
    component:clientViewProject
  }, 
  {
    path: '/services',
    name: 'clientServices',
    component:clientServices
  }, 
  {
    path: '/services/:id',
    name: 'clientViewServices',
    component:clientViewServices
  }, 
  {
    path: '/MyProfile',
    name: 'clientProfile',
    component:clientProfile
  }, 
  









]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
