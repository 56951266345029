<template>
  <div>
    <v-row class="d-flex justify-center mt-12 mb-4">
      <h2>{{ langkeyword("MYCART") }}</h2>
    </v-row>

    <v-row class="alert mt-4">
      <v-col
        class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
        v-if="error_msg != null"
      >
        <v-alert dense type="warning">{{ error_msg }}</v-alert>
      </v-col>
      <v-col
        class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
        v-if="success_msg != null"
      >
        <v-alert dense type="success">{{ success_msg }}</v-alert>
      </v-col>
    </v-row>

    <v-container class="mb-10">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="9" lg="9">
          <v-simple-table class="bg_mode rounded-lg">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="">{{ langkeyword("ITEM") }}</th>
                  <th class="">{{ langkeyword("PRICE") }}</th>
                  <th class="">{{ langkeyword("COLOR") }}</th>
                  <th class="">{{ langkeyword("SIZE") }}</th>
                  <th class="text-center">{{ langkeyword("QUANTITY") }}</th>
                  <th class="">{{ langkeyword("SUBTOTAL") }}</th>
                  <th class=""></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in cart" :key="item.id">
                  <td>
                    <v-list-item key="1">
                      <v-list-item-avatar>
                        <v-img
                          :src="uploadPath('/products/' + item.products_avatar)"
                        ></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>
                          <router-link
                            :to="'shop/' + item.product_id"
                            style="
                               {
                                cursor: pointer;
                              }
                            "
                          >
                            <span link v-if="lang() === 'ku'">{{
                              item.products_ku_name
                            }}</span>
                            <span link v-if="lang() === 'ar'">{{
                              item.products_ar_name
                            }}</span>
                            <span link v-if="lang() === 'en'">{{
                              item.products_en_name
                            }}</span>
                          </router-link>
                        </v-list-item-title>
                        <!-- <v-list-item-subtitle>Lorem Ipsum</v-list-item-subtitle> -->
                      </v-list-item-content>
                    </v-list-item>
                  </td>
                  <td>${{ item.price }}</td>
                  <td>
                    <span v-if="lang() === 'ku'">{{
                      item.colors_ku_name
                    }}</span>
                    <span v-if="lang() === 'ar'">{{
                      item.colors_ar_name
                    }}</span>
                    <span v-if="lang() === 'en'">{{
                      item.colors_en_name
                    }}</span>
                  </td>
                  <td>
                    <span v-if="lang() === 'ku'">{{ item.sizes_ku_name }}</span>
                    <span v-if="lang() === 'ar'">{{ item.sizes_ar_name }}</span>
                    <span v-if="lang() === 'en'">{{ item.sizes_en_name }}</span>
                  </td>
                  <td>
                    <div class="d-flex justify-center align-center">
                      <v-btn
                        @click="plus(item.id)"
                        class="white--text"
                        x-small
                        icon
                        style="background: #00e676"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>

                      <v-btn text>{{ item.qty }}</v-btn>

                      <v-btn
                        @click="minus(item.id)"
                        class="white--text"
                        x-small
                        icon
                        style="background: #e63200"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </div>
                  </td>
                  <td>${{ item.subtotal }}</td>
                  <td>
                    <v-btn
                      @click="remove(item.id)"
                      class="white--text"
                      x-small
                      icon
                      style="background: #e63200"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col
          class="bg_mode"
          cols="11"
          md="3"
          lg="3"
          style="border-radius: 10px"
        >
          <p class="mt-5 text-center">{{ langkeyword("TOTAL") }}</p>
          <hr />
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="text-center title" style="width: 50px">
                    <b>${{ total }}</b>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center">
            <v-btn
              v-if="total != 0"
              @click="order"
              class="primary title p-4 w-100 white--text mt-5"
              outlined
              >{{ langkeyword("BUY") }}

              <v-icon>mdi-cart-arrow-down</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <appFooter />
  </div>
</template>

<script>
import appFooter from "../../layouts/client/footer.vue";
import axios from "axios";
export default {
  components: { appFooter },

  data() {
    return {
      auth_id: this.$store.getters.getclientauth.id,
      cart: [],
      error_msg: null,
      success_msg: null,
      total: 0,
    };
  },

  computed: {},
  mounted() {
    axios
      .post("/app/client/cart/getall", {
        getAllCart: true,
        account_id: this.auth_id,
      })
      .then((res) => {
        if (res.data.status === false) {
          this.success_msg = null;
          this.error_msg = this.langkeyword(res.data.msg);
        } else {
          this.error_msg = null;
          this.cart = res.data.data;
          this.total = res.data.total + 0;
        }
        this.cleanMessages();
      })
      .catch((err) => {
        console.log("response error " + err);
      });
  },
  methods: {
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    plus(id) {
      axios
        .post("/app/client/cart/plus", {
          plusCart: true,
          cart_id: id,
          qty: 1,
        })
        .then((res) => {
          if (res.data.status === false) {
            this.success_msg = null;
            this.error_msg = this.langkeyword(res.data.msg);
          } else {
            this.error_msg = null;
            this.cart = res.data.data;
            this.total = res.data.total + 0;
          }
          this.cleanMessages();
        })
        .catch((err) => {
          console.log("response error " + err);
        });
    },
    minus(id) {
      axios
        .post("/app/client/cart/minus", {
          minusCart: true,
          cart_id: id,
          qty: 1,
        })
        .then((res) => {
          if (res.data.status === false) {
            this.success_msg = null;
            this.error_msg = this.langkeyword(res.data.msg);
          } else {
            this.error_msg = null;
            this.cart = res.data.data;
            this.total = res.data.total + 0;
          }
          this.cleanMessages();
        })
        .catch((err) => {
          console.log("response error " + err);
        });
    },
    remove(id) {
      axios
        .post("/app/client/cart/delete", {
          deleteCart: true,
          cart_id: id,
        })
        .then((res) => {
          if (res.data.status === false) {
            this.success_msg = null;
            this.error_msg = this.langkeyword(res.data.msg);
          } else {
            this.error_msg = null;
            this.cart = res.data.data;
            this.total = res.data.total + 0;
          }
          this.cleanMessages();
        })
        .catch((err) => {
          console.log("response error " + err);
        });
    },
    order() {
      axios
        .post("/app/client/cart/order", {
          orderCart: true,
          account_id: this.auth_id,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status === false) {
            this.success_msg = null;
            this.error_msg = this.langkeyword(res.data.msg);
          } else {
            window.location.href=""
          }
          this.cleanMessages();
        })
        .catch((err) => {
          console.log("response error " + err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>