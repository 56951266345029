<template>
  <div>
    <navbar></navbar>
    <v-container fluid class="mt-4 mb-4">
      <v-row class="alert mt-4">
        <v-col
          class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
          v-if="error_msg != null"
        >
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col
          class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
          v-if="success_msg != null"
        >
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>

      <v-container>
        <v-row>
          <v-col>
            <v-btn
              link
              :to="
                '/dashboard/product/products/' + info.product_id + '/colors/'
              "
              :style="lang() === 'en' ? 'float:right' : 'float:left'"
              class="mb-2 mr-2 radius-5 rabar-font"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
              <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
              <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
              {{ langkeyword("BACK") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-row class="d-flex text--purple justify-center">
        <v-col cols="11">
          <v-data-table
            :headers="shownHeaders"
            :items="productavatars"
            :loading="loading"
            :search="search"
            sort-by="name"
            class="elevation-1 rabar-font radius-15"
            :footer-props="{
              showFirstLastPage: true,
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-text': langkeyword('PERPAGE'),
              'items-per-page-options': perPages(),
            }"
            :items-per-page="10"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title
                  ><v-icon>mdi-palette</v-icon>
                  <span v-if="lang() === 'ku'">
                    {{ langkeyword("PRODUCT") + ":  " + info.products_ku_name }}
                  </span>
                  <span v-if="lang() === 'ar'">
                    {{ langkeyword("PRODUCT") + ":  " + info.products_ar_name }}
                  </span>
                  <span v-if="lang() === 'en'">
                    {{ langkeyword("PRODUCT") + ":  " + info.products_en_name }}
                  </span>
                  <span v-if="lang() === 'ku'">
                    {{ langkeyword("COLOR") + ":  " + info.colors_ku_name }}
                  </span>
                  <span v-if="lang() === 'ar'">
                    {{ langkeyword("COLOR") + ":  " + info.colors_ar_name }}
                  </span>
                  <span v-if="lang() === 'en'">
                    {{ langkeyword("COLOR") + ":  " + info.colors_en_name }}
                  </span>
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="700px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="success"
                      style="color: whitefloat:right"
                      class="mb-2 radius-5 rabar-font"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-plus-circle </v-icon>
                      {{ langkeyword("ADD") }}
                    </v-btn>
                  </template>
                  <v-card class="radius-15 bg-mode">
                    <v-card-title>
                      <span class="rabar-font" v-if="isAdd() === true"
                        ><v-icon> mdi-plus-circle</v-icon>
                        {{ langkeyword("ADD") }}</span
                      >
                      <span class="rabar-font" v-if="isEdit() === true"
                        ><v-icon> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}</span
                      >
                    </v-card-title>
                    <v-card-text class="radius-15">
                      <v-container>
                        <v-form>
                          <v-text-field
                            class="rabar-font d-none"
                            append-icon="mdi-format-title"
                            v-model="productavatarsObject.product_id"
                            :label="langkeyword('PRODUCT')"
                          >
                          </v-text-field>

                          <v-text-field
                            class="rabar-font d-none"
                            append-icon="mdi-format-title"
                            v-model="productavatarsObject.color_id"
                            :label="langkeyword('COLOR')"
                          >
                          </v-text-field>

                          <v-file-input
                            v-model="productavatarsObject.avatar"
                            id="avatar"
                            name="avatar"
                            required
                            accept="image/png, image/jpeg, image/bmp"
                            prepend-icon="mdi-camera"
                            :label="langkeyword('AVATAR')"
                          >
                          </v-file-input>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="green darken-1"
                        v-if="isAdd() === true"
                        dark
                        @click="saveProductAvatars"
                      >
                        <v-icon dark> mdi-plus-circle</v-icon>
                        {{ langkeyword("SAVE") }}
                      </v-btn>
                      <v-btn
                        color="orange darken-1"
                        v-if="isEdit() === true"
                        dark
                        @click="updateProductAvatars(productavatarsObject.id)"
                      >
                        <v-icon dark> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" dark @click="closeMainDialog">
                        <v-icon dark>mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <v-icon>mdi-delete-circle</v-icon>
                      {{ langkeyword("DELETE") }}
                    </v-card-title>
                    <v-card-text>
                      {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="red lighten-2"
                        dark
                        @click="deleteProductAvatars(productavatarsObject.id)"
                      >
                        <v-icon>mdi-delete-circle</v-icon>
                        {{ langkeyword("DELETE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        justify="end"
                        color="red darken-2"
                        dark
                        @click="closeDeleteDialog"
                      >
                        <v-icon> mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>

                <v-row>
                  <v-col cols="12" md="10">
                    <v-text-field
                      class="rabar-font"
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="langkeyword('SEARCH')"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:item.edit="{ item }">
              <center class="mt-2 mb-2">
                <v-icon
                  small
                  class="mr-2 mt-2 orange white--text px-2 py-2 rounded"
                  @click="editDialog(item)"
                >
                  mdi-pencil-circle
                </v-icon>
                <v-icon
                  small
                  class="mr-2 mt-2 red white--text px-2 py-2 rounded"
                  @click="deleteDialog(item)"
                >
                  mdi-delete-circle
                </v-icon>
              </center>

              <v-img
                class="white--text mx-auto p-2 mt-4 mb-4 rounded"
                width="400px"
                height="300px"
                :src="uploadPath('/productavatars/' + item.avatar)"
              >
              </v-img>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>
      
      <script>
import Vue from "vue";
import ProductAvatarsRequests from "../../../requests/dashboard/product/ProductAvatars";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
import axios from "axios";
export default {
  components: {
    navbar,
    dashboardFooter,
  },
  data() {
    return {
      info: {},
      dialog: false,
      dialogDelete: false,
      snackbar: true,
      productavatars: [],
      search: "",
      loading:
        Vue.prototype.getters().getproductavatars == null ||
        Vue.prototype.getters().getproductavatars == "" ||
        Vue.prototype.getters().getproductavatars == undefined
          ? true
          : false,
      error_msg: null,
      success_msg: null,
      headers: [
        { text: "#", value: "id", align: "center", show: false },
        {
          text: Vue.prototype.langkeyword("ACTIONS"),
          value: "edit",
          sortable: false,
          align: "center",
          show: true,
        },
      ],
      CURRENTFORM: "add",
      productavatarsObject: {
        user_id: Vue.prototype.dashboardAuthId(),
        product_id: this.$route.params.id,
        color_id: "",
        avatar: "",
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.readData();
      },
    },
  },
  computed: {
    shownHeaders() {
      return this.headers.filter((h) => {
        if (h.show) {
          return h;
        }
      });
    },

    sizes() {
      return this.$store.getters.getsizes;
    },
  },
  mounted() {
    axios
      .post("/app/dashboard/product/productcolors/getone", {
        getOneProductColors: true,
        productcolor_id: this.$route.params.colorid,
      })
      .then((res) => {
        if (res.data.status == true) {
          this.info = res.data.data;
          this.productavatarsObject.product_id = this.$route.params.id;
          this.productavatarsObject.color_id = res.data.data.color_id;
          this.readData();
        } else {
          this.$router.push("/404");
        }
      })
      .catch((err) => {
        console.log("response error " + err);
      });
      this.readData()
  },
  methods: {
    isAdd() {
      if (this.CURRENTFORM === "add") {
        return true;
      } else {
        return false;
      }
    },
    isEdit() {
      if (this.CURRENTFORM === "edit") {
        return true;
      } else {
        return false;
      }
    },
    cleanMessages() {
      this.productavatarsObject = { user_id: Vue.prototype.dashboardAuthId() };
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    validate(params, callback) {
      this.error_msg = null;
      params.forEach((param) => {
        console.log(param);
        if (param == "" || param == undefined || param == null) {
          this.error_msg = this.langkeyword("DATA_REQUIRED");
        }
      });
      if (this.error_msg == null) {
        return callback();
      }
      this.cleanMessages();
    },
    editDialog(item) {
      this.CURRENTFORM = "edit";
      this.productavatarsObject = Object.assign({}, item);
      this.dialog = true;
    },
    lockDialog(item) {
      this.CURRENTFORM = "lock";
      this.productavatarsObject = Object.assign({}, item);
      this.dialog = true;
    },
    deleteDialog(item) {
      this.CURRENTFORM = "delete";
      this.productavatarsObject = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeMainDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.productavatarsObject = Object.assign({}, this.defaultItem);
        this.CURRENTFORM = "add";
      });
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.productavatarsObject = Object.assign({}, this.defaultItem);
        this.CURRENTFORM = "add";
      });
    },
    readData() {
      this.productavatars = this.$store.getters.getproductavatars.filter(
        (pa) => {
          return (
            pa.product_id == this.$route.params.id &&
            pa.color_id == this.info.color_id
          );
        }
      );
    },
    saveProductAvatars() {
      this.validate(
        [
          this.$route.params.id,
          this.info.color_id,
          this.productavatarsObject.avatar,
        ],
        () => {
          const avatarname = document.getElementById("avatar").files[0];
          const addParams = new FormData();
          addParams.append("addProductAvatars", true);
          addParams.append("avatar", avatarname);
          addParams.append("product_id", this.$route.params.id);
          addParams.append("color_id", this.info.color_id);
          addParams.append("user_id", this.productavatarsObject.user_id);
          const config = {
            headers: {
              "content-type": `multipart/form-data; boundary=${addParams._boundary}`,
            },
          };
          ProductAvatarsRequests.add(addParams, config, (res) => {
            if (res.data.status === false) {
              this.error_msg = this.langkeyword(res.data.msg);
            } else if (res.data.status === true) {
              this.error_msg = null;
              this.success_msg = this.langkeyword(res.data.msg);
              this.$store.state.productavatars = res.data.data;
              this.productavatars = res.data.data;
            }
            this.cleanMessages();
          });
        }
      );
      this.closeMainDialog();
    },
    updateProductAvatars(productavatarId) {
      this.validate(
        [
          this.$route.params.id,
          this.info.color_id,
          this.productavatarsObject.avatar,
        ],
        () => {
          const avatarname = document.getElementById("avatar").files[0];
          const editParams = new FormData();
          editParams.append("editProductAvatars", true);
          editParams.append("productavatar_id", productavatarId);
          editParams.append("product_id", this.$route.params.id);
          editParams.append("color_id", this.info.color_id);
          editParams.append("avatar", avatarname);
          const config = {
            headers: {
              "content-type": `multipart/form-data; boundary=${editParams._boundary}`,
            },
          };
          ProductAvatarsRequests.edit(editParams, config, (res) => {
            if (res.data.status === false) {
              this.error_msg = this.langkeyword(res.data.msg);
            } else if (res.data.status === true) {
              this.error_msg = null;
              this.success_msg = this.langkeyword(res.data.msg);
              this.$store.state.productavatars = res.data.data;
              this.productavatars = res.data.data;
            }
            this.cleanMessages();
          });
        }
      );
      this.closeMainDialog();
    },
    deleteProductAvatars(productavatarId) {
      ProductAvatarsRequests.delete(
        { deleteProductAvatars: true, productavatar_id: productavatarId },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.productavatars = res.data.data;
            this.productavatars = res.data.data;
          }
        }
      );
      this.cleanMessages();
      this.closeDeleteDialog();
    },
  },
};
</script>
      

<style>
.sss {
  background: #000 !important;
}
</style>
