<template>
  <div>
    <v-container class="mt-8">
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-12">
          <v-img
            class="text-center mx-auto rounded-lg"
            :src="
              active_image != ''
                ? uploadPath('/productavatars/' + active_image)
                : uploadPath(
                    '/productavatars/' + filtered_productavatars[0].avatar
                  )
            "
          ></v-img>

          <VueSlickCarousel
            class="text-center mt-10"
            style="zoom: 70% !important"
            :responsive="resp2"
            :arrows="false"
            :dots="true"
            :speed="500"
            :slidesToShow="3"
            :slidesToScroll="1"
            :initialSlide="0"
            :autoplay="true"
            :centerMode="true"
          >
            <a
              v-for="(item, index) in filtered_productavatars"
              :key="index"
              @click="select_image(item)"
            >
              <v-img
                class="rounded-lg"
                max-width="180"
                :src="uploadPath('/productavatars/' + item.avatar)"
              >
              </v-img>
            </a>
          </VueSlickCarousel>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <!-- <v-breadcrumbs class="pb-0" :items="breadcrums"></v-breadcrumbs> -->
          <div class="pl-6">
            <v-row class="mb-6">
              <v-col cols="11" md="6" lg="6">
                <v-list style="background: transparent !important">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon>mdi-truck</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        langkeyword("STANDARD_SHIP")
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        langkeyword("free_with")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>

              <v-col cols="11" md="6" lg="6">
                <v-list style="background: transparent !important">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon>mdi-truck-delivery</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        langkeyword("EXP_DELIVERY")
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        >$35,00
                        {{ langkeyword("AVAILABLE") }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-space-between mb-4" align="center">
              <p>{{ langkeyword("QUANTITY") }} : {{ totalqty }}</p>
            </v-row>

            <p class="mb-0" style="font-size: 20px">
              <span v-if="lang() === 'ku'">{{ info.ku_name }}</span>
              <span v-if="lang() === 'ar'">{{ info.ar_name }}</span>
              <span v-if="lang() === 'en'">{{ info.en_name }}</span>
            </p>
            <v-card-actions class="pa-0">
              <p class="headline pt-3" style="color: #e63200">
                ${{ info.sale_price }}
              </p>
              <v-spacer></v-spacer>
            </v-card-actions>

            <v-row class="mt-5">
              <v-col cols="" md=""></v-col>
            </v-row>

            <span class="">{{ langkeyword("COLOR") }}</span>

            <v-row class="d-flex flex-wrap mt-4 mb-5">
              <v-sheet
                v-for="(item, index) in productcolors"
                :key="index"
                class="d-flex justify-center mx-2 rounded"
                style="cursor: pointer"
                :color="item.colors_code"
                :value="item.color_id"
                height="30"
                width="30"
                @click="colorClick(item.color_id)"
              >
              </v-sheet>
            </v-row>

            <v-row class="mt-5">
              <v-col cols="" md=""></v-col>
            </v-row>

            <v-row
              class="d-flex justify-space-between mb-4"
              align="center"
              style="align-items: center !important"
            >
              <span class="mx-2">{{ langkeyword("SIZE") }}</span>
              <v-btn text class="rounded-xl"></v-btn>
            </v-row>

            <v-radio-group row v-model="selected_size">
              <v-radio
                v-for="(item, index) in filtered_productsizes"
                :key="index"
                :label="item.sizes_ku_name"
                :value="item.size_id"
                @click="show_addtocart_components = true"
              >
              </v-radio>
            </v-radio-group>

            <v-row class="mt-3">
              <v-col cols="" md="">
                <v-text-field
                  v-if="auth_id != null && show_addtocart_components == true && color_selected == true"
                  type="number"
                  v-model="selected_quantity"
                  min="1"
                  value="1"
                  :prefix="langkeyword('QUANTITY')"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-btn
              v-if="auth_id != null && show_addtocart_components == true && color_selected == true"
              class="mt-2 black--text rounded-xl mx-4"
              color="#FBB03B"
              style="border-radius: 5px"
              tile
              dense
              @click="addToCart()"
              ><v-icon>mdi-cart</v-icon> {{ langkeyword("ADD_TO_CART") }}</v-btn
            >

            <v-btn
              class="mt-2 black--text rounded-xl mx-4"
              color="#FBB03B"
              style="border-radius: 5px"
              tile
              dense
              @click="resetFilters"
              ><v-icon>mdi-filter</v-icon>
              {{ langkeyword("RESETFILTERS") }}</v-btn
            >
          </div>
        </div>
      </div>

      <v-row class="alert mt-4">
        <v-col
          class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
          v-if="error_msg != null"
        >
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col
          class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
          v-if="success_msg != null"
        >
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>

      <div class="row">
        <div class="col-sm-12 col-xs-12 col-md-12">
          <v-tabs class="bg_mode">
            <v-tab>{{ langkeyword("DESC") }}</v-tab>
            <v-tab-item class="bg_mode rounded-br-lg rounded-bl-lg">
              <p class="pa-10 font-weight-thin bg_mode">
                <span v-if="lang() === 'ku'">{{ info.ku_detail }}</span>
                <span v-if="lang() === 'ar'">{{ info.ar_detail }}</span>
                <span v-if="lang() === 'en'">{{ info.en_detail }}</span>
              </p>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
    </v-container>

    <v-container class="mt-6 mb-10">
      <v-row
        class="d-flex justify-space-between pa-4"
        align="center"
        style="align-items: center !important"
      >
        <span class="mx-2">{{ langkeyword("RELATED_PRODUCTS") }}</span>
        <v-btn outlined class="rounded-xl text-capitalize">{{
          langkeyword("SHOW_MORE")
        }}</v-btn>
      </v-row>

      <v-container class="col-11 px-lg-10 mt-6">
        <VueSlickCarousel
          class="text-center"
          :responsive="resp"
          :arrows="true"
          :dots="false"
          :speed="500"
          :slidesToShow="3"
          :slidesToScroll="3"
          :initialSlide="0"
        >
          <v-card
            v-for="(item, index) in products"
            :class="
              item.productcategory_id === info.productcategory_id &&
              item.id != info.id
                ? ''
                : 'd-none'
            "
            :key="index"
            style="border-radius: 0px !important"
            max-width="300"
            elevation="0"
            max-height="700"
            class="bg_mode text-start"
          >
            <v-img
              class=""
              width="100%"
              height="200"
              :src="uploadPath('/products/' + item.avatar)"
            >
            </v-img>

            <router-link :to="'/shop/' + item.id" style="text-decoration: none">
              <v-card-subtitle class="pb-0 color_mode" style="font-size: 16px">
                <span v-if="lang() === 'ku'">{{ item.ku_name }}</span>
                <span v-if="lang() === 'ar'">{{ item.ar_name }}</span>
                <span v-if="lang() === 'en'">{{ item.en_name }}</span>
              </v-card-subtitle>

              <v-card-subtitle class="pb-0 color-main" style="font-size: 14px">
                ${{ item.sale_price }}
              </v-card-subtitle>
              <v-card-actions>
                <div class="mb-2"></div>
              </v-card-actions>
            </router-link>
          </v-card>
        </VueSlickCarousel>
      </v-container>
    </v-container>

    <appFooter />
  </div>
</template>


  <script>
// import { VueAgile } from "vue-agile";
import axios from "axios";
import appFooter from "../../layouts/client/footer.vue";
import "vueperslides/dist/vueperslides.css";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: {
    appFooter,
    VueSlickCarousel,
  },
  data() {
    return {
      auth_id: this.$store.getters.getclientauth.id,
      info: {},
      active_image: "",
      color_selected:false,
      selected_size: '',
      selected_quantity: 1,
      show_addtocart_components: false,
      filtered_productavatars: [],
      filtered_productsizes: [],
      error_msg: null,
      success_msg: null,
      resp: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],

      resp2: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],

      totalqty: 0,
      asNavFor1: [],
      asNavFor2: [],
    };
  },

  mounted() {
    this.resetFilters();
    axios
      .post("/app/client/products/getone", {
        getOneProducts: true,
        product_id: this.$route.params.id,
      })
      .then((res) => {
        if (res.data.status == true) {
          if (res.data.data.status === "sale") {
            this.info = res.data.data;
            this.totalqty = res.data.totalqty;
          } else {
            console.log(res.data);
            this.$router.push("/404");
          }
        } else {
          console.log(res.data);
          this.$router.push("/404");
        }
      })
      .catch((err) => {
        console.log("response error " + err);
      });

    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main);
  },
  computed: {
    products() {
      return this.$store.state.products
        .filter((entry) => {
          return entry.status === "sale";
        })
        .slice(0, 3);
    },
    productcolors() {
      return this.$store.state.productcolors.filter((entry) => {
        return entry.product_id == this.$route.params.id;
      });
    },
    productsizes() {
      return this.$store.state.productsizes.filter((entry) => {
        return entry.product_id == this.$route.params.id;
      });
    },
    productavatars() {
      return this.$store.state.productavatars.filter((entry) => {
        return entry.product_id == this.$route.params.id;
      });
    },
  },
  methods: {
    resetFilters() {
      this.selected_quantity = 1;
      this.selected_size = 0;
      this.show_addtocart_components = false;
      this.color_selected = false;
      this.filtered_productavatars = null;
      this.filtered_productsizes = null;
      this.filtered_productavatars = this.productavatars;
      this.filtered_productsizes = this.productsizes;
    },
    colorClick(color_id) {
      this.filtered_productavatars = null;
      this.filtered_productsizes = null;
      this.selected_size = '';
      this.filtered_productavatars = this.$store.state.productavatars.filter(
        (entry) => {
          return (
            entry.product_id == this.$route.params.id &&
            entry.color_id == color_id
          );
        }
      );

      this.active_image = "";
      this.filtered_productsizes = this.$store.state.productsizes.filter(
        (entry) => {
          return (
            entry.product_id == this.$route.params.id &&
            entry.color_id == color_id
          );
        }
      );
      this.color_selected = true
      // console.log(this.filtered_productavatars);
    },

    select_image(select_image) {
      this.active_image = select_image.avatar;
    },
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    addToCart() {
      axios
        .post("/app/client/cart/add", {
          addCart: true,
          account_id:this.auth_id,
          product_id: this.$route.params.id,
          color_id: this.filtered_productsizes[0].color_id,
          size_id:this.selected_size,
          qty:this.selected_quantity
        })
        .then((res) => {
          if (res.data.status === false) {
            this.success_msg = null;
            this.error_msg = this.langkeyword(res.data.msg);
          } else {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
          }
          this.resetFilters()
          this.cleanMessages();
        })
        .catch((err) => {
          console.log("response error " + err);
        });
    },
  },
};
</script>
  
  
  <style>
* {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}
</style>
  