<template>
  <div>
    <!-- <navbar/> -->

    <v-container class="show_in_desktop">
      <v-row
        class="d-flex justify-center mt-16 bg_mode pa-3"
        style="border-left: 2px solid #01994c; border-right: 2px solid #01994c"
      >
        <h1 class="color-main">{{ langkeyword("PRODUCTS") }}</h1>
      </v-row>
    </v-container>

    <v-container class="show_in_mobile">
      <v-row class="d-flex justify-center mt-16 pa-3">
        <h1 class="color-main">{{ langkeyword("PRODUCTS") }}</h1>
      </v-row>
    </v-container>

    <!-- items -->
    <div class="mt-6 mb-10">

      <v-row>
        <v-col cols="12" lg="6" sm="8" class="mx-auto text-center">
          <v-text-field
            class="mx-auto text-center bg_mode rounded px-2"
            :label="langkeyword('SEARCH')"
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>



      <v-row class="pa-lg-6 d-flex flex-wrap">
        <v-col cols="12" md="3" lg="3" class="pa-10">
          <v-list class="pa-3 mt-2">
            <v-list-group :value="true">
              <template v-slot:activator>
                <v-list-item-title>{{
                  langkeyword("PRODUCT_TYPE")
                }}</v-list-item-title>
              </template>

              <v-list flat>
                <v-list-item
                  v-for="(item, index) in productcategories"
                  :key="index"
                >
                  <v-checkbox
                    multiple
                    :value="item.id"
                    v-model="productcategories_filter"
                    v-if="lang() === 'ku'"
                    :label="item.ku_name"
                  ></v-checkbox>
                  <v-checkbox
                    multiple
                    :value="item.id"
                    v-model="productcategories_filter"
                    v-if="lang() === 'ar'"
                    :label="item.ar_name"
                  ></v-checkbox>
                  <v-checkbox
                    multiple
                    :value="item.id"
                    v-model="productcategories_filter"
                    v-if="lang() === 'en'"
                    :label="item.en_name"
                  ></v-checkbox>
                </v-list-item>
              </v-list>
            </v-list-group>
          </v-list>
        </v-col>

        <v-col cols="12" md="9" lg="9">
          <v-row
            align="center"
            class="d-flex px-8 justify-space-between"
            style="align-items: center !important"
          >
            <div>
              <p style="font-size: 20px !important">{{langkeyword('PRODUCTS')}}</p>
            </div>

            <div class="d-flex justify-space-around">
              <v-select
                :items="[10,15,20,50,100]"
                v-model="itemnumber"
                id="showProduct"
                dense
                outlined
                hide-details
                @change="show_item_by_number()"
                style="width: 80px"
              ></v-select>
            </div>
          </v-row>

          <!-- card items   -->
          <v-row class="d-flex justify-center flex-wrap pa-2">
            <v-row class="d-flex justify-center" v-if="products.length == 0">
              <h3>{{ langkeyword("NOTFOUND") }}</h3>
            </v-row>

            <v-col
              v-for="(item, index) in products"
              :key="index"
              cols="6"
              sm="6"
              lg="3"
              md="4"
            >
              <v-card
                style="border-radius: 0px !important"
                elevation="0"
                class="mx-auto bg_mode text-center"
                max-width="400"
              >
                <v-img
                  class="white--text mx-auto"
                  width="100%"
                  height="200"
                  :src="uploadPath('/products/' + item.avatar)"
                >
                  <!-- https://cdn3d.iconscout.com/3d/premium/thumb/medicine-jar-4036036-3342345.png -->
                </v-img>
                <h2
                  class="pb-0 px-2 mt-4 text-truncate"
                  style="font-size: 20px; font-weight: 700 !important"
                >
                  <span v-if="lang() === 'ku'">{{ item.ku_name }}</span>
                  <span v-if="lang() === 'ar'">{{ item.ar_name }}</span>
                  <span v-if="lang() === 'en'">{{ item.en_name }}</span>
                </h2>
                <v-card-subtitle class="pb-0" style="font-size: 16px">
                  <span v-if="lang() === 'ku'">{{
                    item.productcategories_ku_name
                  }}</span>
                  <span v-if="lang() === 'ar'">{{
                    item.productcategories_ar_name
                  }}</span>
                  <span v-if="lang() === 'en'">{{
                    item.productcategories_en_name
                  }}</span>
                </v-card-subtitle>

                <v-card-actions>
                  <!-- <v-btn color="primary" class="mx-auto mb-3 mt-2" small><v-icon>mdi mdi-chevron-left</v-icon></v-btn> -->
                  <v-btn
                    :to="'/products/' + item.id"
                    text
                    class="mx-auto mb-3 mt-2 text-capitalize"
                    small
                    >{{ langkeyword("READ_MORE") }}
                    <v-icon class="mx-1" color="#01994C"
                      >mdi mdi-chevron-right-circle</v-icon
                    ></v-btn
                  >

                  <div class="mb-2"></div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <!-- end card items -->

          <v-row class="mb-16"> </v-row>
        </v-col>
      </v-row>
    </div>

    <appFooter />
  </div>
</template>

<script>
// import navbar from "../../../layouts/client/nav.vue";
import appFooter from "../../layouts/client/footer.vue";
export default {
  components: { appFooter },
  data() {
    return {
      itemnumber: 10,
      productcategories_filter: [],
      search:'',
    };
  },
  computed: {
    productcategories() {
      return this.$store.state.productcategories;
    },
    products() {
      if (this.productcategories_filter.length == 0) {
        if(this.search!=''){
          return this.$store.state.products.filter((entry)=>{
            return (this.search.toLowerCase().includes(entry.en_name) || this.search.toLowerCase().includes(entry.ar_name.toLowerCase()) || this.search.toLowerCase().includes(entry.ku_name)) && (entry.status==='blog');
          }).slice(0,this.itemnumber);
        }
        else {
          return this.$store.state.products.filter((entry)=>{
            return entry.status==='blog'
          }).slice(0,this.itemnumber);
        }
      } else {
        if(this.search!=''){
        return this.$store.state.products.filter((entry) => {
          return (this.productcategories_filter.includes(entry.productcategory_id)).slice(0,this.itemnumber) &&
          (this.search.toLowerCase().includes(entry.en_name) || this.search.toLowerCase().includes(entry.ar_name.toLowerCase()) || this.search.toLowerCase().includes(entry.ku_name)) && (entry.status==='blog');
        });
      }
      else {
        return this.$store.state.products.filter((entry) => {
          return this.productcategories_filter.includes(entry.productcategory_id) && (entry.status==='blog');
        }).slice(0,this.itemnumber);
      }
      }
    },
  },
  mounted() {},
  methods: {
    show_item_by_number() {},
    // add_filter(index){
    //   this.amenities.push(index);
    // }

    // checked(){
    //   this.products=this.$store.state.products.filter(entry => {
    //            return this.productcategories_filter.includes(entry.productcategory_id)
    //       })
    // },

    add_to_favorite() {},
  },
};
</script>

<style scoped>
</style>