  <template>
  <div>
    <!-- <navbar/> -->

    <v-container class="show_in_desktop">
      <v-row class="alert mt-4">
        <v-col
          class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
          v-if="error_msg != null"
        >
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col
          class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
          v-if="success_msg != null"
        >
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>

      <v-row
        class="d-flex justify-center mt-16 bg_mode pa-3"
        style="border-left: 2px solid #01994c; border-right: 2px solid #01994c"
      >
        <h1 class="color-main">{{ langkeyword("SHOP") }}</h1>
      </v-row>
    </v-container>

    <v-container class="show_in_mobile">
      <v-row class="d-flex justify-center mt-16 pa-3">
        <h1 class="color-main">{{ langkeyword("SHOP") }}</h1>
      </v-row>
    </v-container>

    <!-- items -->
    <div class="mt-6 mb-10">
      <v-row>
        <v-col cols="12" lg="6" sm="8" class="mx-auto text-center">
          <v-text-field
            class="mx-auto text-center bg_mode rounded px-2"
            :label="langkeyword('SEARCH')"
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="pa-lg-6 d-flex flex-wrap">
        <v-col cols="12" md="3" lg="3" class="pa-10">
          <v-list class="pa-3 mt-2">
            <v-list-group :value="true">
              <template v-slot:activator>
                <v-list-item-title>{{
                  langkeyword("PRODUCT_TYPE")
                }}</v-list-item-title>
              </template>

              <v-list flat>
                <v-list-item
                  v-for="(item, index) in productcategories"
                  :key="index"
                >
                  <v-checkbox
                    multiple
                    :value="item.id"
                    v-model="productcategories_filter"
                    v-if="lang() === 'ku'"
                    :label="item.ku_name"
                  ></v-checkbox>
                  <v-checkbox
                    multiple
                    :value="item.id"
                    v-model="productcategories_filter"
                    v-if="lang() === 'ar'"
                    :label="item.ar_name"
                  ></v-checkbox>
                  <v-checkbox
                    multiple
                    :value="item.id"
                    v-model="productcategories_filter"
                    v-if="lang() === 'en'"
                    :label="item.en_name"
                  ></v-checkbox>
                </v-list-item>
              </v-list>
            </v-list-group>
          </v-list>
        </v-col>

        <v-col cols="12" md="9" lg="9">
          <v-row
            align="center"
            class="d-flex px-8 justify-space-between mb-5"
            style="align-items: center !important"
          >
            <div>
              <p style="font-size: 20px !important">
                {{ langkeyword("PRODUCTS") }}
              </p>
            </div>

            <div class="d-flex justify-space-around">
              <v-select
                :items="[10, 15, 20, 50, 100]"
                v-model="itemnumber"
                dense
                outlined
                hide-details
                style="width: 80px"
              ></v-select>
            </div>
          </v-row>

          <!-- card items   -->
          <div class="d-flex justify-center flex-wrap">
            <v-row class="d-flex justify-center" v-if="products.length == 0">
              <h3>{{ langkeyword("NOTFOUND") }}</h3>
            </v-row>

            <v-col
              v-for="(item, index) in products"
              :key="index"
              cols="6"
              lg="3"
              md="3"
            >
              <v-card
                style="border-radius: 0px !important"
                elevation="0"
                class="mx-auto bg_mode"
                max-width="400"
              >
                <v-img
                  class="white--text mx-auto"
                  width="100%"
                  height="200"
                  :src="uploadPath('/products/' + item.avatar)"
                >
                  <v-btn
                    v-if="$store.state.clientauth.id != null && (checkAddedToCart(item.id)[0])==null"
                    @click="addToCart(item.id)"
                    color="white"
                  >
                    <v-icon color="primary">mdi-heart</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="$store.state.clientauth.id != null && (checkAddedToCart(item.id)[0])!=null && checkAddedToCart(item.id)[0]['product_id']==item.id"
                    link
                    to="/cart" 
                    color="white"
                  > 
                    <v-icon color="red">mdi-heart</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="$store.state.clientauth.id == null"
                    link
                    to="login"
                    color="white"
                  >
                    <v-icon color="primary">mdi-heart</v-icon>
                  </v-btn>
                </v-img>

                <router-link
                  :to="'/shop/' + item.id"
                  style="text-decoration: none"
                >
                  <v-card-subtitle
                    class="pb-0 color_mode"
                    style="font-size: 16px"
                  >
                    <span v-if="lang() === 'ku'">{{ item.ku_name }}</span>
                    <span v-if="lang() === 'ar'">{{ item.ar_name }}</span>
                    <span v-if="lang() === 'en'">{{ item.en_name }}</span>
                  </v-card-subtitle>

                  <v-card-subtitle
                    class="pb-0 color-main"
                    style="font-size: 14px"
                  >
                    $ {{ item.sale_price }}
                  </v-card-subtitle>

                  <v-card-actions>
                    <!-- <v-btn color="primary" class="mx-auto mb-3" small><v-icon>mdi mdi-cart-plus</v-icon></v-btn>
        -->
                    <div class="mb-2"></div>
                  </v-card-actions>
                </router-link>
              </v-card>
            </v-col>
          </div>
          <!-- end card items -->

          <v-row class="mb-16"> </v-row>
        </v-col>
      </v-row>
    </div>
    <appFooter />
  </div>
</template>

  <script>
// import navbar from "../../../layouts/client/nav.vue";
import appFooter from "../../layouts/client/footer.vue";
import axios from "axios";
export default {
  components: { appFooter },
  data() {
    return {
      auth_id: this.$store.getters.getclientauth.id,
      productcategories_filter: [],
      itemnumber: 10,
      error_msg: null,
      success_msg: null,
      sort: "Popular",
      min: 0,
      max: 999,
      checkbox: [],
      range: [0, 999],
      search: "",
    };
  },
  computed: {
    productcategories() {
      return this.$store.state.productcategories;
    },
    products() {
        if (this.productcategories_filter.length === 0) {
        return this.$store.state.products.filter((entry)=>{
            return (entry.en_name.toLowerCase().includes(this.search.toLowerCase()) 
            || entry.ar_name.toLowerCase().includes(this.search.toLowerCase()) 
            || entry.ku_name.toLowerCase().includes(this.search.toLowerCase()));
          }).slice(0,this.itemnumber);
      }else{
    
          return this.$store.state.products.filter((entry)=>{
            return ( this.productcategories_filter.includes(entry.productcategory_id) );
          })

        }
      
        // if (this.search != "") {
        //   return this.$store.state.products
        //     .filter((entry) => {
        //       return (
        //         (this.search.toLowerCase().includes(entry.en_name) ||
        //           this.search
        //             .toLowerCase()
        //             .includes(entry.ar_name.toLowerCase()) ||
        //           this.search.toLowerCase().includes(entry.ku_name)) &&
        //         entry.status === "sale"
        //       );
        //     })
        //     .slice(0, this.itemnumber);
        // } else {
        //   return this.$store.state.products
        //     .filter((entry) => {
        //       return entry.status === "sale";
        //     })
        //     .slice(0, this.itemnumber);
        // }
      // } else {
      //   if (this.search != "") {
      //     return this.$store.state.products.filter((entry) => {
      //       return (
      //         this.productcategories_filter
      //           .includes(entry.productcategory_id)
      //           .slice(0, this.itemnumber) &&
      //         (this.search.toLowerCase().includes(entry.en_name) ||
      //           this.search
      //             .toLowerCase()
      //             .includes(entry.ar_name.toLowerCase()) ||
      //           this.search.toLowerCase().includes(entry.ku_name)) &&
      //         entry.status === "sale"
      //       );
      //     });
      //   } else {
      //     return this.$store.state.products
      //       .filter((entry) => {
      //         return (
      //           this.productcategories_filter.includes(
      //             entry.productcategory_id
      //           ) && entry.status === "sale"
      //         );
      //       })
      //       .slice(0, this.itemnumber);
      //   }
      // }
    },
  },
  mounted() {
  },
  methods: {
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    addToCart(product_id) {
      axios
        .post("/app/client/cart/addsimple", {
          addCart: true,
          account_id: this.auth_id,
          product_id: product_id,
        })
        .then((res) => {
          if (res.data.status === false) {
            this.success_msg = null;
            this.error_msg = this.langkeyword(res.data.msg);
          } else {
            this.$store.state.cart = res.data.data
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
          }
          this.cleanMessages();
        }) 
        .catch((err) => {
          console.log("response error " + err);
        });
    }, 
    checkAddedToCart(product_id){
     return this.$store.state.cart.filter((c)=>{
      return c.product_id==product_id
     })
    },
  },
};
</script>

  <style scoped>
</style>