<template>
  <div>
    
<navbar></navbar>


<v-container>
<v-row no-gutters>
<v-col cols="12" xs="5" sm="6" md="4" lg="3" xl="2" v-for="(item, index) in services" :key="index">
<v-card tile link class="p-2 mx-1 mt-2 py-1 home-card radius-rounded rounded"  >
<v-list-item dense>
<v-list-item-content>
<div class="fs-small rabar">
{{item.name}} 
</div>
<v-list-item-avatar tile size="35" class="rounded">
<v-icon :color="item.color" large>{{item.icon}}</v-icon>
</v-list-item-avatar>
<v-list-item-title class="text-h6 mt-1">
{{item.num}}
</v-list-item-title>
</v-list-item-content>
</v-list-item>
</v-card>
</v-col>
</v-row>
</v-container>






    <div class="mt-16"></div>
      <dashboardFooter/>

  </div>
</template>

<script>
  import navbar from '../../layouts/dashboard/nav.vue'
  import dashboardFooter from '../../layouts/dashboard/dashboardFooter.vue'
  export default {
    data() {
      return {
        services: [
          { 
            name: this.langkeyword('USERS'),
            icon: 'mdi-account',
            num:this.$store.state.dashboardhome.countusers,
            color: 'primary darken-1'
          },
          // { 
          //   name: this.langkeyword('METATAGS'),
          //   icon: 'mdi-card-search',
          //   num:this.$store.state.dashboardhome.countmetas,
          //   color: 'primary darken-1'
          // },
          // { 
          //   name: this.langkeyword('SOCIALS'),
          //   icon: 'mdi-social-distance-2-meters',
          //   num:this.$store.state.dashboardhome.countsocials,
          //   color: 'primary darken-1'
          // },
          { 
            name: this.langkeyword('CONTACTS'),
            icon: 'mdi-card-account-mail',
            num:this.$store.state.dashboardhome.countcontacts,
            color: 'primary darken-1'
          },
          // { 
          //   name: this.langkeyword('STORES'),
          //   icon: 'mdi-store',
          //   num:this.$store.state.dashboardhome.countstores,
          //   color: 'primary darken-1'
          // },
          { 
            name: this.langkeyword('PRODUCTCATEGORIES'),
            icon: 'mdi-clipboard-list',
            num:this.$store.state.dashboardhome.countproductcategories,
            color: 'primary darken-1'
          },
 
          // { 
          //   name: this.langkeyword('BRANDS'),
          //   icon: 'mdi-watermark',
          //   num:this.$store.state.dashboardhome.countbrands,
          //   color: 'primary darken-1'
          // },

          { 
            name: this.langkeyword('SLIDERS'),
            icon: 'mdi-panorama-variant',
            num:this.$store.state.dashboardhome.countsliders,
            color: 'primary darken-1'
          },
          { 
            name: this.langkeyword('PRODUCTS'),
            icon: 'mdi-basket',
            num:this.$store.state.dashboardhome.countproducts,
            color: 'primary darken-1'
          },
          { 
            name: this.langkeyword('PROJECTCATEGORIES'),
            icon: 'mdi-clipboard-list',
            num:this.$store.state.dashboardhome.countprojectcategories,
            color: 'primary darken-1'
          },
          { 
            name: this.langkeyword('PROJECTS'),
            icon: 'mdi-projector',
            num:this.$store.state.dashboardhome.countprojects,
            color: 'primary darken-1'
          },
          { 
            name: this.langkeyword('SERVICETYPES'),
            icon: 'mdi-clipboard-list',
            num:this.$store.state.dashboardhome.countservicetypes,
            color: 'primary darken-1'
          },
          { 
            name: this.langkeyword('SERVICES'),
            icon: 'mdi-shape',
            num:this.$store.state.dashboardhome.countservices,
            color: 'primary darken-1'
          },
          { 
            name: this.langkeyword('COLORS'),
            icon: 'mdi-palette',
            num:this.$store.state.dashboardhome.countservices,
            color: 'primary darken-1'
          },
          { 
            name: this.langkeyword('SIZES'),
            icon: 'mdi-resize',
            num:this.$store.state.dashboardhome.countservices,
            color: 'primary darken-1'
          },
          { 
            name: this.langkeyword('ORDERS'),
            icon: 'mdi-basket',
            num:this.$store.state.dashboardhome.countorders,
            color: 'primary darken-1'
          },
          { 
            name: this.langkeyword('ORDER_WATING'),
            icon: 'mdi-basket',
            num:this.$store.state.dashboardhome.getorders.filter(entry=>{
              return entry.status==='ordered'
            }).length,
            color: 'primary darken-1'
          },

          { 
            name: this.langkeyword('ORDER_CHECKING'),
            icon: 'mdi-basket',
            num:this.$store.state.dashboardhome.getorders.filter(entry=>{
              return entry.status==='checking'
            }).length,
            color: 'primary darken-1'
          },

          { 
            name: this.langkeyword('ORDER_DELIVERING'),
            icon: 'mdi-basket',
            num:this.$store.state.dashboardhome.getorders.filter(entry=>{
              return entry.status==='delivering'
            }).length,
            color: 'primary darken-1'
          },

          { 
            name: this.langkeyword('ORDER_DELIVERED'),
            icon: 'mdi-basket',
            num:this.$store.state.dashboardhome.getorders.filter(entry=>{
              return entry.status==='delivered'
            }).length,
            color: 'primary darken-1'
          },






        ]
      }
    },
    components: {
      navbar,dashboardFooter
    },
    methods: {

    },
    mounted(){
      
    }
  }
</script>


<style>
  @font-face {
    font-family: "rabar";
    src: local("rabar"),
      url(../../assets/fonts/Rabar_039.ttf) format("truetype");
  }

  .radius-rounded {
    border-radius: 15px !important;
  }

  .rabar {
    font-family: "rabar" !important;
  }
  
  .fs-small {
    font-size:.9rem;
  }

  .theme--dark .dashboard .home-card:hover{
    background:#393B56 !important;
    transition: ease 0.2s !important;
  }
  .theme--light .dashboard .home-card:hover{
    background:#ECEAEA !important;
    transition: ease 0.2s !important;
  }
</style>