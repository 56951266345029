import axios from 'axios'

 

export default {

    async getAll(params,callback){
        return await axios.post("/app/dashboard/orders/getall",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },

    
    async delete(params,callback){
        return await axios.post("/app/dashboard/orders/delete",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },


    async changestatus(params,callback){
        return await axios.post("/app/dashboard/orders/changestatus",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.log('response error '+err)
        })
    },


}
 
