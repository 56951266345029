import Vue from 'vue'
import Vuex from 'vuex'
import AppRequests from '../requests/App'
Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    actlang: null,
    serverurl: "https://smartliftiraq.com/",
    dashboardauth: {},
    dashboardhome: {},
    clientauth: {},
    clienthome: {},
    setting: {},
    users: [],
    metas: [],
    contacts: [],
    socials: [],
    stores: [],
    productcategories: [],
    brands: [],
    sliders: [],
    accounts: [],
    products: [],
    projects: [],
    projectavatars: [],
    projectcategories: [],
    services: [],
    servicetypes: [],

    colors: [],
    sizes: [],
    productcolors: [],
    productsizes: [],
    productavatars: [],
    productgalaries: [],

    orders: [],
    orderitems: [],
    cart: [],



    direction: '',
  },
  getters: {
    getusers(state) {
      return state.users
    },
    getmetas(state) {
      return state.metas
    },
    gecontacts(state) {
      return state.contacts
    },
    getdashboardauth(state) {
      return state.dashboardauth
    },
    getdashboardhome(state) {
      return state.dashboardhome
    },
    getclientauth(state) {
      return state.clientauth
    },
    getclienthome(state) {
      return state.clienthome
    },
    getlang(state) {
      return state.lang
    },
    getdrirection(state) {
      return state.drirection
    },
    getsetting(state) {
      return state.setting
    },
    getsocials(state) {
      return state.socials
    },

    getstores(state) {
      return state.stores
    },

    getbrands(state) {
      return state.brands
    },

    getsliders(state) {
      return state.sliders
    },

    getaccounts(state) {
      return state.accounts
    },

    getproductcategories(state) {
      return state.productcategories
    },

    getproducts(state) {
      return state.products
    },

    getprojects(state) {
      return state.projects
    },

    getprojectavatars(state) {
      return state.projectavatars
    },

    getprojectcategories(state) {
      return state.projectcategories
    },

    getservices(state) {
      return state.services
    },

    getservicetypes(state) {
      return state.servicetypes
    },

    getcolors(state) {
      return state.colors
    },
    getsizes(state) {
      return state.sizes
    },
    getproductcolors(state) {
      return state.productcolors
    },
    getproductsizes(state) {
      return state.productsizes
    },
    getproductavatars(state) {
      return state.productavatars
    },
    getproductgalaries(state) {
      return state.productgalaries
    },
    getorders(state) {
      return state.orders
    },
    getorderitems(state) {
      return state.orderitems
    },
    getcart(state) {
      return state.cart
    },
  },
  mutations: {
    fetchData(state) {
      AppRequests.getAll({ isLoadApp: true }, (res) => {
        state.setting = res.data.fetchsetting
        state.socials = res.data.getsocials
        state.metas = res.data.getmetas
        state.contacts = res.data.getcontacts
        state.users = res.data.getusers
        state.stores = res.data.getstores
        state.productcategories = res.data.getproductcategories
        state.brands = res.data.getbrands
        state.sliders = res.data.getsliders
        state.accounts = res.data.getaccounts
        state.products = res.data.getproducts
        state.projects = res.data.getprojects
        state.projectavatars = res.data.getprojectavatars
        state.projectcategories = res.data.getprojectcategories
        state.services = res.data.getservices
        state.servicetypes = res.data.getservicetypes
        state.colors = res.data.getcolors
        state.sizes = res.data.getsizes
        state.productcolors = res.data.getproductcolors
        state.productsizes = res.data.getproductsizes
        state.productavatars = res.data.getproductavatars
        state.productgalaries = res.data.getproductgalaries
        state.orders = res.data.getorders
        state.orderitems = res.data.getorderitems
        state.cart = res.data.getcart
      })
      AppRequests.countAll({ isLoadApp: true }, (res) => {
        state.dashboardhome = res.data
        state.clienthome = res.data
      })
    }
  },
  actions: {

  },
  modules: {
  }
})
